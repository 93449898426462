import axios from 'axios';

import { INFO_QUERY } from '@/services/common';
import type { ResponseData } from '@/services/common/types';
import { isSuccess } from '@/services/utils';

import { seatPermissionKeys, settingKeys, teamPermissionKeys } from './constants';
import type {
  CreateSsoMethodBody,
  CreateSsoMethodResponse,
  DeleteSsoMethodResponse,
  GenerateSCIMTokenResponse,
  GetMACAddressListResponse,
  GetNotifyComputerListResponse,
  GetTeamSettingResponse,
  GetTrustDeviceResponse,
  IPWhitelistKind,
  ImportSsoMetadataResponse,
  ImportSsoMetadataUrl,
  ImportSsoMetadataUrlResponse,
  IpWhiteList,
  LimitedDevice,
  NotifyComputerKind,
  ResetDefaultSsoMethodResponse,
  SetDefaultSsoMethodResponse,
  SetVendorSosPackageResponse,
  SettingKey,
  SsoMethodData,
  SsoMethodList,
  ToggleVendorStatusResponse,
  TrustedDevice,
  UpdateMACAddressListPayload,
  UpdateMACAddressListResponse,
  UpdateNotifyComputerPayload,
  UpdateSettingBody,
  UpdateSettingResponse,
  UpdateSsoMethodBody,
  UpdateSsoMethodResponse,
  UpdateSsoMethodStatusBody,
  UpdateSsoMethodStatusResponse,
  UpdateTeamNameResponse,
  UpdateVendorApiKeyResponse,
  VendorDataSet,
} from './types';

export function getTeamSettings() {
  return new Promise<GetTeamSettingResponse>((resolve, reject) => {
    axios
      .post<ResponseData & { data: GetTeamSettingResponse }>(INFO_QUERY, {
        team_settings: settingKeys,
        team_permissions: teamPermissionKeys,
        seat_permissions: seatPermissionKeys,
      })
      .then((res) => {
        const {
          data: { result, messages, data },
        } = res;
        if (isSuccess(result)) {
          resolve(data);
        } else {
          reject({ statusCode: result, messages, errorReason: data });
        }
      })
      .catch((error) => {
        const statusCode = error.response ? error.response.status : 9487;
        reject({ statusCode, messages: [], errorReason: {} });
      });
  });
}

export function getSingleSetting(settingKey: SettingKey) {
  return new Promise<GetTeamSettingResponse>((resolve, reject) => {
    axios
      .post<ResponseData & { data: GetTeamSettingResponse }>(INFO_QUERY, {
        team_settings: [settingKey],
      })
      .then((res) => {
        const {
          data: { result, messages, data },
        } = res;
        if (isSuccess(result)) {
          resolve(data);
        } else {
          reject({ statusCode: result, messages, errorReason: data });
        }
      })
      .catch((error) => {
        const statusCode = error.response ? error.response.status : 9487;
        reject({ statusCode, messages: [], errorReason: {} });
      });
  });
}

export function getMultipleSetting(settingKeys: Array<SettingKey>) {
  return new Promise<GetTeamSettingResponse>((resolve, reject) => {
    axios
      .post<ResponseData & { data: GetTeamSettingResponse }>(INFO_QUERY, {
        team_settings: settingKeys,
      })
      .then((res) => {
        const {
          data: { result, messages, data },
        } = res;
        if (isSuccess(result)) {
          resolve(data);
        } else {
          reject({ statusCode: result, messages, errorReason: data });
        }
      })
      .catch((error) => {
        const statusCode = error.response ? error.response.status : 9487;
        reject({ statusCode, messages: [], errorReason: {} });
      });
  });
}

export function updateTeamName(teamId: number, newName: string) {
  return new Promise<UpdateTeamNameResponse>((resolve, reject) => {
    axios
      .patch<ResponseData & { data: UpdateTeamNameResponse }>(`/api/web/v1/teams/${teamId}/rename`, { name: newName })
      .then((res) => {
        const {
          data: { result, messages, data },
        } = res;
        if (isSuccess(result)) {
          resolve(data);
        } else {
          reject({ statusCode: result, messages, errorReason: data });
        }
      })
      .catch((error) => {
        const statusCode = error.response ? error.response.status : 9487;
        reject({ statusCode, messages: [], errorReason: {} });
      });
  });
}

/**
 * @deprecated Use `updateTeamSettingsService` from `@/services/teams/team_settings/updateTeamSettingsService` instead
 */
export function updateTeamSettings(teamId: number, settings: Array<UpdateSettingBody>) {
  return new Promise<UpdateSettingResponse>((resolve, reject) => {
    axios
      .patch<ResponseData & { data: UpdateSettingResponse }>(`/api/web/v1/teams/${teamId}/team_settings`, { settings })
      .then((res) => {
        const {
          data: { result, messages, data },
        } = res;
        if (isSuccess(result)) {
          resolve(data);
        } else {
          reject({ statusCode: result, messages, errorReason: data });
        }
      })
      .catch((error) => {
        const statusCode = error.response ? error.response.status : 9487;
        reject({ statusCode, messages: [], errorReason: {} });
      });
  });
}

export function shutdownTeam(teamId: number) {
  return new Promise<void>((resolve, reject) => {
    axios
      .delete<ResponseData & { data: Record<string, never> }>(`/api/web/v1/teams/${teamId}/shutdown`)
      .then((res) => {
        const {
          data: { result, messages, data },
        } = res;
        if (isSuccess(result)) {
          resolve();
        } else {
          reject({ statusCode: result, messages, errorReason: data });
        }
      })
      .catch((error) => {
        const statusCode = error.response ? error.response.status : 9487;
        reject({ statusCode, messages: [], errorReason: {} });
      });
  });
}

export function getVendorSettings(teamId: number) {
  return new Promise<VendorDataSet>((resolve, reject) => {
    axios
      .get<ResponseData & { data: VendorDataSet }>(`/api/web/v1/teams/${teamId}/settings/vendors`)
      .then((res) => {
        const {
          data: { result, messages, data },
        } = res;
        if (isSuccess(result)) {
          resolve(data);
        } else {
          reject({ statusCode: result, messages, errorReason: data });
        }
      })
      .catch((error) => {
        const statusCode = error.response ? error.response.status : 9487;
        reject({ statusCode, messages: [], errorReason: {} });
      });
  });
}

export function toggleVendorStatus(teamId: number, vendorId: number, status: boolean) {
  return new Promise<ToggleVendorStatusResponse>((resolve, reject) => {
    axios
      .post<ResponseData & { data: ToggleVendorStatusResponse }>(`/api/web/v1/teams/${teamId}/settings/vendors`, {
        vendor_id: vendorId,
        vendor_status: status,
      })
      .then((res) => {
        const {
          data: { result, messages, data },
        } = res;
        if (isSuccess(result)) {
          resolve(data);
        } else {
          reject({ statusCode: result, messages, errorReason: data });
        }
      })
      .catch((error) => {
        const statusCode = error.response ? error.response.status : 9487;
        reject({ statusCode, messages: [], errorReason: {} });
      });
  });
}

export function updateVenderApiKey(teamId: number, vendorId: number) {
  return new Promise<UpdateVendorApiKeyResponse>((resolve, reject) => {
    axios
      .patch<ResponseData & { data: UpdateVendorApiKeyResponse }>(`/api/web/v1/teams/${teamId}/settings/vendors/${vendorId}`)
      .then((res) => {
        const {
          data: { result, messages, data },
        } = res;
        if (isSuccess(result)) {
          resolve(data);
        } else {
          reject({ statusCode: result, messages, errorReason: data });
        }
      })
      .catch((error) => {
        const statusCode = error.response ? error.response.status : 9487;
        reject({ statusCode, messages: [], errorReason: {} });
      });
  });
}

export function setVendorSosPackage(teamId: number, vendorId: number, sosPackageId: number) {
  return new Promise<SetVendorSosPackageResponse>((resolve, reject) => {
    axios
      .post<ResponseData & { data: SetVendorSosPackageResponse }>(`/api/web/v1/teams/${teamId}/settings/vendors/set_vendor_package`, {
        vendor_id: vendorId,
        sos_package_id: sosPackageId,
      })
      .then((res) => {
        const {
          data: { result, messages, data },
        } = res;
        if (isSuccess(result)) {
          resolve(data);
        } else {
          reject({ statusCode: result, messages, errorReason: data });
        }
      })
      .catch((error) => {
        const statusCode = error.response ? error.response.status : 9487;
        reject({ statusCode, messages: [], errorReason: {} });
      });
  });
}

export function getSsoMethodList(teamId: number) {
  return new Promise<SsoMethodList>((resolve, reject) => {
    axios
      .get<ResponseData & { data: { xauth_methods: SsoMethodList } }>(`/api/web/v1/teams/${teamId}/settings/xauth_methods`)
      .then((res) => {
        const {
          data: { result, messages, data },
        } = res;
        if (isSuccess(result)) {
          resolve(data.xauth_methods);
        } else {
          reject({ statusCode: result, messages, errorReason: data });
        }
      })
      .catch((error) => {
        const statusCode = error.response ? error.response.status : 9487;
        reject({ statusCode, messages: [], errorReason: {} });
      });
  });
}

export function createSsoMethod(teamId: number, ssoMethodData: CreateSsoMethodBody) {
  return new Promise<CreateSsoMethodResponse>((resolve, reject) => {
    axios
      .post<ResponseData & { data: CreateSsoMethodResponse }>(`/api/web/v1/teams/${teamId}/settings/xauth_methods`, ssoMethodData)
      .then((res) => {
        const {
          data: { result, messages, data },
        } = res;
        if (isSuccess(result)) {
          resolve(data);
        } else {
          reject({ statusCode: result, messages, errorReason: data });
        }
      })
      .catch((error) => {
        const statusCode = error.response ? error.response.status : 9487;
        reject({ statusCode, messages: [], errorReason: {} });
      });
  });
}

export function getSsoMethodData(teamId: number, ssoMethodId: number) {
  return new Promise<SsoMethodData>((resolve, reject) => {
    axios
      .get<ResponseData & { data: SsoMethodData }>(`/api/web/v1/teams/${teamId}/settings/xauth_methods/${ssoMethodId}`)
      .then((res) => {
        const {
          data: { result, messages, data },
        } = res;
        if (isSuccess(result)) {
          resolve(data);
        } else {
          reject({ statusCode: result, messages, errorReason: data });
        }
      })
      .catch((error) => {
        const statusCode = error.response ? error.response.status : 9487;
        reject({ statusCode, messages: [], errorReason: {} });
      });
  });
}

export function updateSsoMethodData(teamId: number, ssoMethodId: number, ssoMethodData: UpdateSsoMethodBody) {
  return new Promise<UpdateSsoMethodResponse>((resolve, reject) => {
    axios
      .patch<ResponseData & { data: UpdateSsoMethodResponse }>(
        `/api/web/v1/teams/${teamId}/settings/xauth_methods/${ssoMethodId}`,
        ssoMethodData,
      )
      .then((res) => {
        const {
          data: { result, messages, data },
        } = res;
        if (isSuccess(result)) {
          resolve(data);
        } else {
          reject({ statusCode: result, messages, errorReason: data });
        }
      })
      .catch((error) => {
        const statusCode = error.response ? error.response.status : 9487;
        reject({ statusCode, messages: [], errorReason: {} });
      });
  });
}

export function deleteSsoMethod(teamId: number, ssoMethodId: number) {
  return new Promise<DeleteSsoMethodResponse>((resolve, reject) => {
    axios
      .delete<ResponseData & { data: DeleteSsoMethodResponse }>(`/api/web/v1/teams/${teamId}/settings/xauth_methods/${ssoMethodId}`)
      .then((res) => {
        const {
          data: { result, messages, data },
        } = res;
        if (isSuccess(result)) {
          resolve(data);
        } else {
          reject({ statusCode: result, messages, errorReason: data });
        }
      })
      .catch((error) => {
        const statusCode = error.response ? error.response.status : 9487;
        reject({ statusCode, messages: [], errorReason: {} });
      });
  });
}

export function updateSsoMethodStatus(teamId: number, ssoMethodId: number, data: UpdateSsoMethodStatusBody) {
  return new Promise<UpdateSsoMethodStatusResponse>((resolve, reject) => {
    axios
      .patch<ResponseData & { data: UpdateSsoMethodStatusResponse }>(
        `/api/web/v1/teams/${teamId}/settings/xauth_methods/${ssoMethodId}/change_status`,
        data,
      )
      .then((res) => {
        const {
          data: { result, messages, data },
        } = res;
        if (isSuccess(result)) {
          resolve(data);
        } else {
          reject({ statusCode: result, messages, errorReason: data });
        }
      })
      .catch((error) => {
        const statusCode = error.response ? error.response.status : 9487;
        reject({ statusCode, messages: [], errorReason: {} });
      });
  });
}

export function setDefaultSsoMethod(teamId: number, ssoMethodId: number) {
  return new Promise<SetDefaultSsoMethodResponse>((resolve, reject) => {
    axios
      .post<ResponseData & { data: SetDefaultSsoMethodResponse }>(
        `/api/web/v1/teams/${teamId}/settings/xauth_methods/${ssoMethodId}/set_default_sso`,
      )
      .then((res) => {
        const {
          data: { result, messages, data },
        } = res;
        if (isSuccess(result)) {
          resolve(data);
        } else {
          reject({ statusCode: result, messages, errorReason: data });
        }
      })
      .catch((error) => {
        const statusCode = error.response ? error.response.status : 9487;
        reject({ statusCode, messages: [], errorReason: {} });
      });
  });
}

export function resetDefaultSso(teamId: number) {
  return new Promise<ResetDefaultSsoMethodResponse>((resolve, reject) => {
    axios
      .post<ResponseData & { data: ResetDefaultSsoMethodResponse }>(`/api/web/v1/teams/${teamId}/settings/xauth_methods/reset_default_sso`)
      .then((res) => {
        const {
          data: { result, messages, data },
        } = res;
        if (isSuccess(result)) {
          resolve(data);
        } else {
          reject({ statusCode: result, messages, errorReason: data });
        }
      })
      .catch((error) => {
        const statusCode = error.response ? error.response.status : 9487;
        reject({ statusCode, messages: [], errorReason: {} });
      });
  });
}

export function importSsoMetadataFile(teamId: number, file: string) {
  return new Promise<ImportSsoMetadataResponse>((resolve, reject) => {
    axios
      .post<ResponseData & { data: ImportSsoMetadataResponse }>(`/api/web/v1/teams/${teamId}/settings/xauth_methods/import_metadata_file`, {
        file,
      })
      .then((res) => {
        const {
          data: { result, messages, data },
        } = res;
        if (isSuccess(result)) {
          resolve(data);
        } else {
          reject({ statusCode: result, messages, errorReason: data });
        }
      })
      .catch((error) => {
        const statusCode = error.response ? error.response.status : 9487;
        reject({ statusCode, messages: [], errorReason: {} });
      });
  });
}

export function importSsoMetadataUrl(teamId: number, data: ImportSsoMetadataUrl) {
  return new Promise<ImportSsoMetadataUrlResponse>((resolve, reject) => {
    axios
      .post<ResponseData & { data: ImportSsoMetadataUrlResponse }>(
        `/api/web/v1/teams/${teamId}/settings/xauth_methods/import_metadata_url`,
        data,
      )
      .then((res) => {
        const {
          data: { result, messages, data },
        } = res;
        if (isSuccess(result)) {
          resolve(data);
        } else {
          reject({ statusCode: result, messages, errorReason: data });
        }
      })
      .catch((error) => {
        const statusCode = error.response ? error.response.status : 9487;
        reject({ statusCode, messages: [], errorReason: {} });
      });
  });
}

export function getIpWhiteList(teamId: number, kind: IPWhitelistKind) {
  return new Promise<IpWhiteList>((resolve, reject) => {
    axios
      .get<ResponseData & { data: IpWhiteList }>(`/api/web/v1/teams/${teamId}/team_ip_whitelists`, {
        params: {
          kind,
        },
      })
      .then((res) => {
        const {
          data: { result, messages, data },
        } = res;
        if (isSuccess(result)) {
          resolve(data);
        } else {
          reject({ statusCode: result, messages, errorReason: data });
        }
      })
      .catch((error) => {
        const statusCode = error.response ? error.response.status : 9487;
        reject({ statusCode, messages: [], errorReason: {} });
      });
  });
}

export function updateIpWhiteList(teamId: number, kind: IPWhitelistKind, ipList: Array<string>) {
  return new Promise<void>((resolve, reject) => {
    axios
      .patch<ResponseData & { data: Record<string, never> }>(`/api/web/v1/teams/${teamId}/team_ip_whitelists`, {
        kind,
        ips: ipList,
      })
      .then((res) => {
        const {
          data: { result, messages, data },
        } = res;
        if (isSuccess(result)) {
          resolve();
        } else {
          reject({ statusCode: result, messages, errorReason: data });
        }
      })
      .catch((error) => {
        const statusCode = error.response ? error.response.status : 9487;
        reject({ statusCode, messages: [], errorReason: {} });
      });
  });
}

export function importSSOMethodUser(teamId: number, methodId: number, payload: { file: string; filename: string }) {
  return new Promise<void>((resolve, reject) => {
    axios
      .post<ResponseData & { data: Record<string, never> }>(
        `/api/web/v1/teams/${teamId}/settings/xauth_methods/${methodId}/import_user`,
        payload,
      )
      .then((res) => {
        const {
          data: { result, messages, data },
        } = res;
        if (isSuccess(result)) {
          resolve();
        } else {
          reject({ statusCode: result, messages, errorReason: data });
        }
      })
      .catch((error) => {
        const statusCode = error.response ? error.response.status : 9487;
        reject({ statusCode, messages: [], errorReason: {} });
      });
  });
}

export function generateSCIMToken(teamId: number) {
  return new Promise<GenerateSCIMTokenResponse>((resolve, reject) => {
    axios
      .post<ResponseData & { data: GenerateSCIMTokenResponse }>(`/api/web/v1/teams/${teamId}/settings/scim_tokens`)
      .then((res) => {
        const {
          data: { result, messages, data },
        } = res;
        if (isSuccess(result)) {
          resolve(data);
        } else {
          reject({ statusCode: result, messages, errorReason: data });
        }
      })
      .catch((error) => {
        const statusCode = error.response ? error.response.status : 9487;
        reject({ statusCode, messages: [], errorReason: {} });
      });
  });
}

export function getTrustedDeviceList(teamId: number) {
  return new Promise<Array<TrustedDevice>>((resolve, reject) => {
    axios
      .get<ResponseData & { data: GetTrustDeviceResponse }>(`/api/web/v1/teams/${teamId}/settings/trusted_devices`)
      .then((res) => {
        const {
          data: { result, messages, data },
        } = res;
        if (isSuccess(result)) {
          resolve(data.trust_devices ?? []);
        } else {
          reject({ statusCode: result, messages, errorReason: data });
        }
      })
      .catch((error) => {
        const statusCode = error.response ? error.response.status : 9487;
        reject({ statusCode, messages: [], errorReason: {} });
      });
  });
}

export function destroyTrustedDevice(teamId: number, deviceId: number) {
  return new Promise<void>((resolve, reject) => {
    axios
      .delete<ResponseData>(`/api/web/v1/teams/${teamId}/settings/trusted_devices/${deviceId}`)
      .then((res) => {
        const {
          data: { result, messages },
        } = res;
        if (isSuccess(result)) {
          resolve();
        } else {
          reject({ statusCode: result, messages, errorReason: {} });
        }
      })
      .catch((error) => {
        const statusCode = error.response ? error.response.status : 9487;
        reject({ statusCode, messages: [], errorReason: {} });
      });
  });
}

export function getTeamLimitedDeviceList(teamId: number) {
  return new Promise<Array<LimitedDevice>>((resolve, reject) => {
    axios
      .get<ResponseData & { data: { team_limited_devices: Array<LimitedDevice> } }>(
        `/api/web/v1/teams/${teamId}/settings/team_limited_devices`,
      )
      .then((res) => {
        const {
          data: { result, messages, data },
        } = res;
        if (isSuccess(result)) {
          resolve(data.team_limited_devices);
        } else {
          reject({ statusCode: result, messages, errorReason: {} });
        }
      })
      .catch((error) => {
        const statusCode = error.response ? error.response.status : 9487;
        reject({ statusCode, messages: [], errorReason: {} });
      });
  });
}

export function destroyTeamLimitedDevice(teamId: number, deviceId: number) {
  return new Promise<void>((resolve, reject) => {
    axios
      .delete<ResponseData>(`/api/web/v1/teams/${teamId}/settings/team_limited_devices/${deviceId}`)
      .then((res) => {
        const {
          data: { result, messages },
        } = res;
        if (isSuccess(result)) {
          resolve();
        } else {
          reject({ statusCode: result, messages, errorReason: {} });
        }
      })
      .catch((error) => {
        const statusCode = error.response ? error.response.status : 9487;
        reject({ statusCode, messages: [], errorReason: {} });
      });
  });
}

export function getNotifyComputerList(teamId: number, kind: NotifyComputerKind) {
  return new Promise<GetNotifyComputerListResponse>((resolve, reject) => {
    axios
      .get<ResponseData & { data: GetNotifyComputerListResponse }>(`/api/web/v1/teams/${teamId}/settings/notify_computers`, {
        params: { notify_kind: kind },
      })
      .then((res) => {
        const {
          data: { result, messages, data },
        } = res;
        if (isSuccess(result)) {
          resolve(data);
        } else {
          reject({ statusCode: result, messages, errorReason: {} });
        }
      })
      .catch((error) => {
        const statusCode = error.response ? error.response.status : 9487;
        reject({ statusCode, messages: [], errorReason: {} });
      });
  });
}

export function updateNotifyComputers(teamId: number, payload: UpdateNotifyComputerPayload) {
  return new Promise<void>((resolve, reject) => {
    axios
      .post<ResponseData>(`/api/web/v1/teams/${teamId}/settings/notify_computers/update_notify_computers`, payload)
      .then((res) => {
        const {
          data: { result, messages },
        } = res;
        if (isSuccess(result)) {
          resolve();
        } else {
          reject({ statusCode: result, messages, errorReason: {} });
        }
      })
      .catch((error) => {
        const statusCode = error.response ? error.response.status : 9487;
        reject({ statusCode, messages: [], errorReason: {} });
      });
  });
}

export function getMACAddressList(teamId: number) {
  return new Promise<GetMACAddressListResponse>((resolve, reject) => {
    axios
      .get<ResponseData & { data: GetMACAddressListResponse }>(`/api/web/v1/teams/${teamId}/team_macaddr_whitelists`)
      .then((res) => {
        const {
          data: { result, messages, data },
        } = res;
        if (isSuccess(result)) {
          resolve(data);
        } else {
          reject({ statusCode: result, messages, errorReason: {} });
        }
      })
      .catch((error) => {
        const statusCode = error.response ? error.response.status : 9487;
        reject({ statusCode, messages: [], errorReason: {} });
      });
  });
}

export function updateMACAddressList(teamId: number, payload: UpdateMACAddressListPayload) {
  return new Promise<UpdateMACAddressListResponse>((resolve, reject) => {
    axios
      .patch<ResponseData & { data: UpdateMACAddressListResponse }>(`/api/web/v1/teams/${teamId}/team_macaddr_whitelists`, payload)
      .then((res) => {
        const {
          data: { result, messages, data },
        } = res;
        if (isSuccess(result)) {
          resolve(data);
        } else {
          // 當有人也正在更新 mac address whitelists 時會回傳 40416, error: not_allowed_to_do_this
          reject({ statusCode: result, messages, errorReason: {} });
        }
      })
      .catch((error) => {
        const statusCode = error.response ? error.response.status : 9487;
        reject({ statusCode, messages: [], errorReason: {} });
      });
  });
}
