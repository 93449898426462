import axios from 'axios';
import { z } from 'zod';

import { updateKeySchema } from '@/services/teamSettings/constants';
import { UpdateKey } from '@/services/teamSettings/types';
import { checkResponse } from '@/services/utils';

const METHOD = 'PATCH';

const getUrl = (teamId: number) => `/api/web/v1/teams/${teamId}/team_settings`;

const schema = z
  .object({
    setting_type: updateKeySchema,
    result: z.boolean(),
  })
  .array();

// TODO: Define the actual payload type without `any` ?
type Payload = Array<{
  setting_type: UpdateKey;
  update_status?: any;
  additional_settings?: any;
}>;

const execute = (teamId: number, payload: Payload) =>
  checkResponse(axios.request({ url: getUrl(teamId), method: METHOD, data: payload }), schema);

export const updateTeamSettingsService = {
  getUrl,
  execute,
  method: METHOD,
} as const;
