import { useMutation } from '@tanstack/react-query';
import { chunk, concat } from 'lodash';
import merge from 'lodash/merge';

import { useTeamId } from '@/models/TeamInformation';
import { queryClient } from '@/pages/_app';
import {
  Platform,
  assignEmmPolicyToGroupsService,
  assignEmmPolicyToServersService,
  removeEmmPolicyFromGroupsByPlatformService,
  unassignEmmPolicyToGroupsService,
  unassignEmmPolicyToServersService,
} from '@/services/teams/emm_policies';
import { CHUNK_SIZE } from '@/utils/constants';

import { useGroupPolicyListQuery } from './useGroupPolicyListQuery';
import { useServerPolicyListQuery } from './useServerPolicyListQuery';

// Concat the chunked results
const concatResult = (res: Array<{ success: Array<number>; fail: Array<number> }>) => {
  return res.reduce(
    (acc, { success, fail }) => ({
      success: concat(acc.success, success),
      fail: concat(acc.fail, fail),
    }),
    { success: [] as Array<number>, fail: [] as Array<number> },
  );
};

const useBatchAssignGroupMutation = (teamId: number) => {
  return useMutation({
    mutationFn: async ({ policyId, groupsToAssign }: { policyId: number; groupsToAssign: Array<number> }) => {
      const groupsChunks = chunk(groupsToAssign, CHUNK_SIZE);
      const requests = groupsChunks.map(async (groupsChunk, i) => {
        try {
          // Randomly wait for a period of time for each request.
          await new Promise((resolve) => setTimeout(resolve, (i + Math.random() * 0.5) * 100));
          return assignEmmPolicyToGroupsService.execute(teamId, policyId, { group_ids: groupsChunk });
        } catch {
          return {
            success: [] as Array<number>,
            fail: groupsChunk,
          };
        }
      });
      return await Promise.all(requests).then(concatResult);
    },
  });
};

const useBatchUnassignGroupMutation = (teamId: number) => {
  return useMutation({
    mutationFn: async ({ policyId, groupsToUnassign }: { policyId: number; groupsToUnassign: Array<number> }) => {
      const groupsChunks = chunk(groupsToUnassign, CHUNK_SIZE);
      const requests = groupsChunks.map(async (groupsChunk, i) => {
        try {
          // Randomly wait for a period of time for each request.
          await new Promise((resolve) => setTimeout(resolve, (i + Math.random() * 0.5) * 100));
          return unassignEmmPolicyToGroupsService.execute(teamId, policyId, { group_ids: groupsChunk });
        } catch {
          return {
            success: [] as Array<number>,
            fail: groupsChunk,
          };
        }
      });
      return await Promise.all(requests).then(concatResult);
    },
  });
};

const useBatchAssignGroupToTeamDefaultMutation = (teamId: number) => {
  return useMutation({
    mutationFn: async ({ platform, groupsToAssign }: { platform: Platform; groupsToAssign: Array<number> }) => {
      const groupsChunks = chunk(groupsToAssign, CHUNK_SIZE);
      const requests = groupsChunks.map(async (groupsChunk, i) => {
        try {
          // Randomly wait for a period of time for each request.
          await new Promise((resolve) => setTimeout(resolve, (i + Math.random() * 0.5) * 100));
          return removeEmmPolicyFromGroupsByPlatformService.execute(teamId, { group_ids: groupsChunk, platform });
        } catch {
          return {
            success: [] as Array<number>,
            fail: groupsChunk,
          };
        }
      });
      return await Promise.all(requests).then(concatResult);
    },
  });
};

const useBatchAssignServerMutation = (teamId: number) => {
  return useMutation({
    mutationFn: async ({ policyId, serversToAssign }: { policyId: number; serversToAssign: Array<number> }) => {
      const serversChunks = chunk(serversToAssign, CHUNK_SIZE);
      const requests = serversChunks.map(async (serversChunk, i) => {
        try {
          // Randomly wait for a period of time for each request.
          await new Promise((resolve) => setTimeout(resolve, (i + Math.random() * 0.5) * 100));
          return assignEmmPolicyToServersService.execute(teamId, policyId, { server_ids: serversChunk });
        } catch {
          return {
            success: [] as Array<number>,
            fail: serversChunk,
          };
        }
      });
      return await Promise.all(requests).then(concatResult);
    },
  });
};

const useBatchUnassignServerMutation = (teamId: number) => {
  return useMutation({
    mutationFn: async ({ policyId, serversToUnassign }: { policyId: number; serversToUnassign: Array<number> }) => {
      const serversChunks = chunk(serversToUnassign, CHUNK_SIZE);
      const requests = serversChunks.map(async (serversChunk, i) => {
        try {
          // Randomly wait for a period of time for each request.
          await new Promise((resolve) => setTimeout(resolve, (i + Math.random() * 0.5) * 100));
          return unassignEmmPolicyToServersService.execute(teamId, policyId, { server_ids: serversChunk });
        } catch {
          return {
            success: [] as Array<number>,
            fail: serversChunk,
          };
        }
      });
      return await Promise.all(requests).then(concatResult);
    },
  });
};

type UseAssignPolicyMutationProps = {
  onSuccess: (data: {
    groups: { success: Array<number>; fail: Array<number> };
    servers: { success: Array<number>; fail: Array<number> };
  }) => void;
};

type PolicyAssignMutationProps = {
  policyId: number;
  platform: Platform;
  groupsToAssign: Array<number>;
  groupsToUnassign: Array<number>;
  groupsToUnassignByPlatform: Array<number>;
  serversToAssign: Array<number>;
  serversToUnassign: Array<number>;
};

export const useAssignPolicyMutation = ({ onSuccess }: UseAssignPolicyMutationProps) => {
  const teamId = useTeamId();
  const groupPolicyListQueryKey = useGroupPolicyListQuery({ enabled: false }).queryKey;
  const serverPolicyListQueryKey = useServerPolicyListQuery({ enabled: false }).queryKey;
  const batchAssignGroupMutation = useBatchAssignGroupMutation(teamId);
  const batchUnassignGroupMutation = useBatchUnassignGroupMutation(teamId);
  const batchAssignGroupoToTeamDefaultMutation = useBatchAssignGroupToTeamDefaultMutation(teamId);
  const batchAssignServerMutation = useBatchAssignServerMutation(teamId);
  const batchUnassignServerMutation = useBatchUnassignServerMutation(teamId);

  return useMutation({
    mutationFn: async ({
      policyId,
      platform,
      groupsToAssign,
      groupsToUnassign,
      groupsToUnassignByPlatform,
      serversToAssign,
      serversToUnassign,
    }: PolicyAssignMutationProps) => {
      // Groups to assign
      const groupsAssignRequest =
        groupsToAssign.length > 0
          ? batchAssignGroupMutation.mutateAsync({ policyId, groupsToAssign })
          : Promise.resolve({ success: [] as Array<number>, fail: [] as Array<number> });
      // Groups to unassign
      const groupsUnassignRequest =
        groupsToUnassign.length > 0
          ? batchUnassignGroupMutation.mutateAsync({ policyId, groupsToUnassign })
          : Promise.resolve({ success: [] as Array<number>, fail: [] as Array<number> });
      // Groups to unassign by platform (assign to team default policy)
      const groupsUnassignByPlatformRequest =
        groupsToUnassignByPlatform.length > 0
          ? batchAssignGroupoToTeamDefaultMutation.mutateAsync({ platform, groupsToAssign: groupsToUnassignByPlatform })
          : Promise.resolve({ success: [] as Array<number>, fail: [] as Array<number> });
      // Servers to assign
      const serversAssignRequest =
        serversToAssign.length > 0
          ? batchAssignServerMutation.mutateAsync({ policyId, serversToAssign })
          : Promise.resolve({ success: [] as Array<number>, fail: [] as Array<number> });
      // Servers to unassign
      const serversUnassignRequest =
        serversToUnassign.length > 0
          ? batchUnassignServerMutation.mutateAsync({ policyId, serversToUnassign })
          : Promise.resolve({ success: [] as Array<number>, fail: [] as Array<number> });

      const [groupAssignResults, serverAssignResults, groupsUnassignResults, serversUnassignResults, groupsUnassignByPlatformResult] =
        await Promise.all([
          groupsAssignRequest,
          serversAssignRequest,
          groupsUnassignRequest,
          serversUnassignRequest,
          groupsUnassignByPlatformRequest,
        ]);
      return {
        groups: merge({}, groupAssignResults, groupsUnassignResults, groupsUnassignByPlatformResult),
        servers: merge({}, serverAssignResults, serversUnassignResults),
      };
    },
    onSettled: async () => {
      await queryClient.invalidateQueries({ queryKey: groupPolicyListQueryKey });
      await queryClient.invalidateQueries({ queryKey: serverPolicyListQueryKey });
    },
    onSuccess,
  });
};
