import * as Sentry from '@sentry/nextjs';
import { useMutation } from '@tanstack/react-query';
import { useAtomValue, useSetAtom } from 'jotai';
import { useTranslation } from 'next-i18next';

import { type EditableSettingType, type RoleSwitchSettingKey, RoleSwitchUpdateKey, updateTeamSettings } from '@/services/teamSettings';

import { queryMainKey } from '../constants';
import { showErrorMessageOnRailsPage } from '../utils';
import { useTeamControlContext } from './useTeamControlContext';

type SingleRoleUpdatePayload = {
  type?: 'single';
  settingKey: EditableSettingType;
  updateKey: RoleSwitchUpdateKey;
  role: RoleSwitchSettingKey;
  enabled: boolean;
};
type MultipleRoleUpdatePayload = {
  type: 'multiple';
  settingKey: EditableSettingType;
  updateKey: RoleSwitchUpdateKey;
  roleMap: Partial<Record<RoleSwitchSettingKey, boolean>>;
};
type Payload = SingleRoleUpdatePayload | MultipleRoleUpdatePayload;

/**
 * Mutation hook for role switch.
 */
export function useFeatureRoleSwitchMutation() {
  const { t } = useTranslation();

  const { atoms } = useTeamControlContext();
  const teamMetadata = useAtomValue(atoms.teamMetadataAtom);
  const dispatch = useSetAtom(atoms.teamSettingsAtom);

  return useMutation({
    mutationKey: [queryMainKey, 'mutateRoleSwitch'],
    mutationFn: async (payload: Payload) => {
      if (payload.type === 'multiple') {
        const { updateKey, roleMap } = payload;
        return updateTeamSettings(
          teamMetadata.team_id,
          Object.entries(roleMap).map(([role, enabled]) => ({ setting_type: updateKey, update_status: { [role]: enabled } })),
        );
      }

      // Single role update
      const { updateKey, role, enabled } = payload;
      return updateTeamSettings(teamMetadata.team_id, [{ setting_type: updateKey, update_status: { [role]: enabled } }]);
    },
    onMutate: (payload: Payload) => {
      if (payload.type === 'multiple') {
        const { settingKey, roleMap } = payload;
        dispatch({
          type: 'update',
          updateDraft: (draft) => {
            const settingState = draft[settingKey];
            if (settingState && settingState.role_switch) {
              settingState.role_switch = { ...settingState.role_switch, ...roleMap };
            }
          },
        });
        return;
      }

      // Single role update
      const { settingKey, role, enabled } = payload;
      dispatch({
        type: 'update',
        updateDraft: (draft) => {
          const settingState = draft[settingKey];
          if (settingState && settingState.role_switch) {
            settingState.role_switch = { ...settingState.role_switch, [role]: enabled };
          }
        },
      });
    },
    onSuccess: () => {
      dispatch({ type: 'save' });
    },
    onError: (error) => {
      Sentry.captureException(error);
      showErrorMessageOnRailsPage(t('common:unexpectedError'));
      dispatch({ type: 'rollback' });
    },
  });
}
