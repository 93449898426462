import { ReadMode } from '@/modules/TeamSettings/components/RoleSwitch/types';
import { GranularControlKey } from '@/services/team/types';
import { EditableSettingType, RoleSwitchSettingKey, RoleSwitchUpdateKey } from '@/services/teamSettings';

import { useFeatureGranularControlMutation } from './useFeatureGranularControlMutation';
import { useFeatureGranularControlValue } from './useFeatureGranularControlValue';
import { useFeatureRoleSwitchMutation } from './useFeatureRoleSwitchMutation';
import { useFeatureState } from './useFeatureState';

type Payload = {
  readMode: ReadMode;
  settingKey: EditableSettingType;
  roleSwitchUpdateKey?: RoleSwitchUpdateKey;
  granularControlKey?: GranularControlKey;
  role: RoleSwitchSettingKey;
};
type ReturnType = {
  display: boolean;
  value: boolean;
  onChange: (value: boolean) => void;
};

/**
 * Hook for handling role switch setting logic.
 *
 * @see {@link ReadMode}
 */
export function useFeatureRoleSwitch({ readMode, settingKey, roleSwitchUpdateKey, granularControlKey, role }: Payload): ReturnType {
  const featureState = useFeatureState(settingKey);
  const granularControlValue = useFeatureGranularControlValue(granularControlKey);

  const roleSwitchMutation = useFeatureRoleSwitchMutation();
  const granularControlMutation = useFeatureGranularControlMutation();

  return {
    display: computeDisplay(),
    value: computeValue(),
    onChange: (value: boolean) => {
      // Both `readMode` will write both the `role_switch` and granular control API for the capacity of upgrade/downgrade the team plan.
      // A setting key has a specific role switch update key, and a specific granular control key.

      if (roleSwitchUpdateKey) {
        roleSwitchMutation.mutate({ settingKey, updateKey: roleSwitchUpdateKey, role, enabled: value });
      }

      if (granularControlKey) {
        granularControlMutation.mutate({
          controlKey: granularControlKey,
          type: 'normal',
          value: computeUpdateGranularControlValue(value),
        });
      }
    },
  };

  function computeDisplay() {
    if (!roleSwitchUpdateKey && !granularControlKey) {
      return false; // No key to update
    }

    switch (readMode) {
      case 'roleSwitch':
        return featureState?.role_switch !== undefined;
      case 'granularControl':
        return granularControlValue?.normal !== undefined;
    }
  }

  function computeValue() {
    switch (readMode) {
      case 'roleSwitch':
        return featureState?.role_switch?.[role] === true;
      case 'granularControl':
        return granularControlValue?.normal?.[role] === 'on';
      default:
        return false;
    }
  }

  function computeUpdateGranularControlValue(value: boolean) {
    return {
      ...(granularControlValue?.normal ?? {
        owner: 'off',
        manager: 'off',
        group_admin: 'off',
        member: 'off',
      }),
      [role]: value ? 'on' : 'off',
    };
  }
}
