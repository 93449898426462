import { z } from 'zod';

import { featureControl } from '@/feature/toggle';

// Keys to read config from backend

export const settingKeys = [
  'file',
  'file_transfer',
  'off_session_file',
  'remote_print',
  'device_redirect',
  'remote_mic',
  'copy_paste',
  'keystroke',
  'wake_on_lan',
  'remote_reboot',
  'chat',
  'chat_transcript',
  'off_session_chat',
  'off_session_chat_transcript',
  'voice_call',
  'sos_voice_call',
  'session_recording',
  'sos_session_recording',
  'share_screen',
  'sos_share_screen',
  'multi_session',
  'sos_multi_session',
  'watermark',
  'sos_watermark',
  'command_prompt',
  'rdp_computer',
  'one_to_many',
  'create_group_admin',
  'os_logon_info',
  'vulnerability_score',
  'member_list',
  'display_group_to_member',
  'member_share_computer',
  'save_credential',
  'save_security_code',
  'client_stay_login',
  'client_idle_logout',
  'multiple_member_session',
  'member_concurrent_session',
  'member_disconnect_session',
  'member_reboot_computer',
  'restrict_pcp_ip',
  'restrict_src_ip',
  'session_notification',
  'file_session_notification',
  'cmpt_session_notification',
  'sos_session_notification',
  'sos_file_session_notification',
  'sos_cmpt_session_notification',
  'web_src',
  'sos_member_list',
  ...(featureControl.getToggle('PCP_506__TeamSettings_RoleManagement')
    ? ['required_2sv' as const]
    : (['force_member_2step_auth', 'force_admin_2step_auth'] as const)),
  'schedule_time_zone',
  'sta_management_tab',
  '2sv_trust',
  '2sv_trust_ttl',
  '2sv_replace_device_auth',
  'sos_accept_team',
  'sos_decline_team',
  'sos_quit_team',
  'sos_seat_enabled',
  'sos_seat_disabled',
  'sos_user_removed',
  'sos_exceeding_max_user_license_limit',
  'stb_accept_team',
  'stb_decline_team',
  'stb_quit_team',
  'stb_computer_added',
  'stb_computer_removed',
  'team_change_role_to_manager',
  'team_change_role_to_member',
  'stb_seat_enabled',
  'stb_seat_disabled',
  'stb_exceeding_max_user_license_limit',
  'stb_user_removed',
  'srs_to_online',
  'srs_to_offline',
  'srs_conn_notification',
  'srs_disconn_notification',
  'srs_conn_notify_all_pcs',
  'srs_disconn_notify_all_pcs',
  'srs_to_online_notify_all_pcs',
  'srs_to_offline_notify_all_pcs',
  'device_auth_notify',
  'device_auth_notify_browser',
  'device_auth_ttl',
  'device_auth_ttl_browser',
  'srs_download',
  'extra_src_management',
  'blank_computer_list',
  'pcp_idle_timeout',
  'threats_notification',
  'granular_control',
  'scim',
  'vnc_computer',
  'computer_notes_permission',
  'sos_web_src',
  'premium_tool',
  'premium_session_notification',
  'macaddr_whitelist',
  'multiple_concurrent_licenses',
  'service_desk_private_customer_survey',
  'relay_recording',
  'sos_relay_recording',
  'relay_recording_access',
  ...(featureControl.getToggle('PCP_506__TeamSettings_RoleManagement') ? [] : ['relay_recording_access_mode' as const]),
  'sos_relay_recording_access',
  ...(featureControl.getToggle('PCP_506__TeamSettings_RoleManagement') ? [] : ['sos_relay_recording_access_mode' as const]),
  'sos_service_desk_chat_transcript_display',
  'service_desk_private_package_id',
  'background_action_session_notification',
  'ssh_computer',
  'update_service',
  'event_logs',
  'wacom_bridge',
  'service_desk_custom_pin_code_widget',
  ...(featureControl.getToggle('PCP_1249__TeamSettings__SetUpNotificationEmails__95PercentComputerQuotaLimitAlert')
    ? ['computer_exceeds_percentage' as const]
    : []),
  ...(featureControl.getToggle('PCP_1335__TeamSettings_ManageGroupAssociates') ? ['multiple_groups' as const] : []),
  ...(featureControl.getToggle('PCP_1380__TeamSettings_EndpointVersionControl')
    ? (['allow_src_version_control', 'allow_srs_version_control'] as const)
    : []),
  ...(featureControl.getToggle('PCP_1190__TeamSettings_PasswordRotation') ? (['password_rotation', 'password_rotation_ttl'] as const) : []),
] as const;
export const SettingKeyMap = z.enum(settingKeys).enum;
// Keys to update config to backend

/**
 * NOTE: These keys are depend on the feature toggle `PCP_506__TeamSettings_RoleManagement`
 *
 * Can be merged with `updateKeys` if the feature toggle is released
 */
export const roleSwitchUpdateKeys = [
  'one_to_many_role_switch',
  'premium_tool_role_switch',
  'event_logs_role_switch',
  'watermark_role_switch',
  'copy_paste_role_switch',
  'required_2sv_role_switch',
] as const;

export const updateKeys = [
  'file',
  'file_mode',
  'file_transfer',
  'file_transfer_mode',
  'off_session_file',
  'off_session_file_mode',
  'remote_print',
  'device_redirect',
  'device_redirect_detail',
  'remote_mic',
  'copy_paste',
  'copy_paste_mode',
  'keystroke',
  'wake_on_lan',
  'remote_reboot',
  'chat',
  'chat_transcript',
  'off_session_chat',
  'off_session_chat_transcript',
  'voice_call',
  'sos_voice_call',
  'session_recording',
  'sos_session_recording',
  'session_recording_detail',
  'sos_session_recording_detail',
  'share_screen',
  'sos_share_screen',
  'multi_session',
  'sos_multi_session',
  'watermark',
  'sos_watermark',
  'watermark_detail',
  'sos_watermark_detail',
  'command_prompt',
  'rdp_computer',
  'one_to_many',
  'one_to_many_mode',
  'create_group_admin',
  'os_logon_info',
  'vulnerability_score',
  'member_list',
  'sos_member_list',
  'display_group_to_member',
  'member_share_computer',
  'save_credential',
  'save_security_code',
  'client_stay_login',
  'client_idle_logout',
  'multiple_member_session',
  'member_concurrent_session',
  'member_disconnect_session',
  'member_reboot_computer',
  'member_reboot_computer_detail',
  'restrict_pcp_ip',
  'restrict_src_ip',
  'session_notification',
  'file_session_notification',
  'cmpt_session_notification',
  'sos_session_notification',
  'sos_file_session_notification',
  'sos_cmpt_session_notification',
  'allow_close_attended_banner',
  'allow_close_attended_file_banner',
  'allow_close_attended_cmpt_banner',
  'allow_close_unattended_banner',
  'allow_close_unattended_file_banner',
  'allow_close_unattended_cmpt_banner',
  'mail_settings',
  'web_src',
  ...(featureControl.getToggle('PCP_506__TeamSettings_RoleManagement')
    ? ['required_2sv' as const]
    : (['force_member_2step_auth', 'force_admin_2step_auth'] as const)),
  'schedule_time_zone',
  'sta_management_tab',
  '2sv_trust',
  '2sv_trust_ttl',
  '2sv_replace_device_auth',
  'team_change_role_to_manager',
  'team_change_role_to_member',
  'sos_accept_team',
  'sos_decline_team',
  'sos_quit_team',
  'sos_seat_enabled',
  'sos_seat_disabled',
  'sos_user_removed',
  'sos_exceeding_max_user_license_limit',
  'stb_accept_team',
  'stb_decline_team',
  'stb_quit_team',
  'stb_seat_enabled',
  'stb_seat_disabled',
  'stb_user_removed',
  'stb_exceeding_max_user_license_limit',
  'stb_computer_added',
  'stb_computer_removed',
  'srs_to_online',
  'srs_to_offline',
  'srs_conn_notification',
  'srs_disconn_notification',
  'srs_conn_notify_all_pcs',
  'srs_disconn_notify_all_pcs',
  'srs_to_online_notify_all_pcs',
  'srs_to_offline_notify_all_pcs',
  'device_auth_notify',
  'device_auth_ttl',
  'device_auth_notify_browser',
  'device_auth_ttl_browser',
  'srs_download',
  'force_infra_gen',
  'extra_src_management',
  'blank_computer_list',
  'pcp_idle_timeout',
  'threats_notification',
  'vnc_computer',
  'computer_notes_permission',
  'sos_web_src',
  'premium_tool',
  'premium_tool_mode',
  'premium_session_notification',
  'allow_close_unattended_cmpt_banner',
  'macaddr_whitelist',
  'macaddr_whitelist_skip_already_authenticated_devices',
  'macaddr_whitelist_skip_mobile_devices',
  'macaddr_whitelist_skip_device_auth',
  'mail_settings',
  'sos_mail_settings',
  'allow_close_unattended_premium_banner',
  'multiple_concurrent_licenses',
  'service_desk_private_customer_survey',
  'service_desk_private_customer_survey_detail',
  'relay_recording',
  'sos_relay_recording',
  'relay_recording_access',
  'relay_recording_access_mode',
  'sos_relay_recording_access',
  'sos_relay_recording_access_mode',
  'sos_service_desk_chat_transcript_display',
  'service_desk_private_package_id',
  'background_action_session_notification',
  'allow_close_background_action_banner',
  'ssh_computer',
  'update_service',
  'event_logs',
  'event_logs_mode',
  'wacom_bridge',
  'service_desk_custom_pin_code_widget',
  ...(featureControl.getToggle('PCP_1249__TeamSettings__SetUpNotificationEmails__95PercentComputerQuotaLimitAlert')
    ? ['computer_exceeds_percentage' as const]
    : []),
  ...(featureControl.getToggle('PCP_1335__TeamSettings_ManageGroupAssociates') ? ['multiple_groups' as const] : []),
  ...(featureControl.getToggle('PCP_1380__TeamSettings_EndpointVersionControl')
    ? ([
        'allow_src_version_control',
        'allow_src_version_control_detail',
        'allow_srs_version_control',
        'allow_srs_version_control_detail',
      ] as const)
    : []),
  ...(featureControl.getToggle('PCP_458__TeamSettings_EmailDeviceAuthentication_AllowSuperAdmin')
    ? (['device_auth_notify_detail', 'device_auth_notify_browser_detail'] as const)
    : []),
  ...(featureControl.getToggle('PCP_1190__TeamSettings_PasswordRotation') ? (['password_rotation', 'password_rotation_ttl'] as const) : []),
  ...(featureControl.getToggle('PCP_506__TeamSettings_RoleManagement') ? roleSwitchUpdateKeys : []),
] as const;
export const updateKeySchema = z.enum(updateKeys);
export const UpdateKeyMap = updateKeySchema.enum;

export const teamPermissionKeys = [
  'attended_access',
  'sos_ticket_session',
  'sso',
  'extra_src_management',
  'create_group_admin',
  'sos_sso',
  'access_schedule',
  'sos_service_desk',
] as const;

export const seatPermissionKeys = [
  'watermark',
  'sos_watermark',
  'premium_tool',
  'file_transfer',
  'off_session_file',
  'command_prompt',
  'macaddr_whitelist',
  'restrict_pcp_ip',
  'restrict_src_ip',
  'relay_recording',
  'sos_relay_recording',
  'session_recording',
  'sos_session_recording',
  'one_to_many',
  ...(featureControl.getToggle('PCP_1190__TeamSettings_PasswordRotation') ? (['attended_access'] as const) : []),
] as const;
