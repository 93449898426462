import { PLATFORMS } from '@/services/teams/emm_policies/types';

/**
 * @description policy item config for software patch
 * @doc "0301[0-9]{3}[1-9]{1}" and 0302[0-9]{3}[1-9]{1} are for "add software"
 */
export const patchPolicyItemConfig = {
  enable_policy: {
    code: '03000001',
  },
  service_provider: {
    code: '03000002',
  },
  scan_schedule: {
    code: '03000003',
  },
  update_schedule: {
    code: '03000004',
  },
  make_up_scan: {
    code: '03000005',
  },
  make_up_update: {
    code: '03000006',
  },
  onboarding_scan_update: {
    code: '03000007',
  },
  update_after_release: {
    code: '03000008',
  },
  reboot_interval: {
    code: '03000009',
  },
} as const;

export const sourceProviderMap = {
  [PLATFORMS.Windows]: 1,
  [PLATFORMS.macOS]: 2,
  // TODO: Add value for Android, PCP_2647__Policy_support_android_platform
} as const;

export const updateAfterReleaseDayMap = {
  none: 0,
  sevenDay: 7,
  fourteenDay: 14,
  thirtyDay: 30,
} as const;

export const rebootIntervalSecondMap = {
  none: 0,
  fifteenMinutes: 900,
  thirtyMinutes: 1800,
  sixtyMinutes: 3600,
  ninetyMinutes: 5400,
  oneHundredTwentyMinutes: 7200,
} as const;

export const notifyBeforeRebootSecondMap = {
  none: 0,
  twoMinutes: 120,
  threeMinutes: 180,
  fourMinutes: 240,
  fiveMinutes: 300,
} as const;
