import { ComputerData, ComputerWindowsUpdatesPolicy, ComputerWindowsUpdatesStatus } from '@/services/computers';
import { ComputerVersion } from '@/services/computers/types';
import { ComputerGroup } from '@/services/group/types';
import { computeWindowsUpdateStatusInfoType } from '@/utils/computers';

export const WindowsUpdatesStatusInfo = {
  importantUpdates: 4,
  optionalUpdates: 3,
  updating: 2,
  installedWaitRestart: 1, // Will remove this status in the future
  disabled: 5,
  noUpdates: 0,
} as const;
export type WindowsUpdatesStatusInfoValues = (typeof WindowsUpdatesStatusInfo)[keyof typeof WindowsUpdatesStatusInfo];

export type WindowsUpdatesComputerData = {
  id: string;
  gid: string;
  computerName: string;
  groupName: string;
  onlineStatus: boolean;
  version: ComputerVersion;
  os: string;
  status: ComputerWindowsUpdatesStatus;
  statusInfoType: WindowsUpdatesStatusInfoValues | null;
  important: number | null;
  optional: number | null;
  policy: ComputerWindowsUpdatesPolicy;
  policyDescription: string | null;
  lastUpdateTime: string | null;
};

type PolicyKeys = NonNullable<NonNullable<ComputerData['windows_updates']>['policy']>;

export const formatWindowsUpdatesComputer = (
  computer: ComputerData,
  group: ComputerGroup,
  options: { policyTexts: Record<PolicyKeys, string> },
): WindowsUpdatesComputerData => {
  const { policyTexts } = options;
  const status = computer.windows_updates?.status ?? null;
  const policy = computer?.windows_updates?.policy ?? null;
  const important = computer?.windows_updates?.important_count ?? null;
  const optional = computer?.windows_updates?.optional_count ?? null;

  const statusInfoType = computeWindowsUpdateStatusInfoType(policy, status, important, optional);

  return {
    id: String(computer.id),
    gid: String(computer.group_id),
    computerName: computer.name,
    groupName: group.name,
    os: computer.os ?? '',
    version: computer.version ?? ('' as ComputerVersion),
    onlineStatus: Boolean(computer.online_status),
    status,
    statusInfoType,
    important,
    optional,
    policy,
    policyDescription: policy ? policyTexts[policy] : null,
    lastUpdateTime: computer?.windows_updates?.last_updated_at ?? null,
  };
};

export type WindowsUpdatesGroupData = {
  id: string;
  name: string;
};

export const formatWindowsUpdatesGroup = (group: ComputerGroup): WindowsUpdatesGroupData => {
  return {
    ...group,
    id: `${group.id ?? 'null'}`,
  };
};
