import { useRouter } from 'next/router';

import * as Sentry from '@sentry/nextjs';
import { useMutation } from '@tanstack/react-query';
import { useTranslation } from 'next-i18next';
import { z } from 'zod';

import { useRippleFlashMessage } from '@/design';
import { useTeamId } from '@/models/TeamInformation';
import { queryClient } from '@/pages/_app';
import { createEmmPolicyService, listEmmPoliciesService } from '@/services/teams/emm_policies';

import { TwoStepVerificationErrorMessage } from '../components';
import { usePoliciesQueryKey } from './usePoliciesQuery';

type Policies = z.infer<typeof listEmmPoliciesService.responseSchema>;

export const useCreatePolicyMutation = () => {
  const { t } = useTranslation();
  const teamId = useTeamId();
  const { flashMessage } = useRippleFlashMessage();
  const router = useRouter();
  const policiesQueryKey = usePoliciesQueryKey();

  return useMutation({
    mutationFn: (payload: z.infer<typeof createEmmPolicyService.payloadSchema>) => createEmmPolicyService.execute(teamId, payload),
    onSuccess: async (data) => {
      // Update new created policy into policy list
      queryClient.setQueryData(policiesQueryKey, (oldData?: Policies) => {
        if (!oldData) {
          return [data];
        }
        return [...oldData, data];
      });
      // No need to show flash message when creating a prepared team default policy
      if (data.super_root) {
        return;
      }
      flashMessage({ title: t('common:created_successfully'), variant: 'success' });
    },
    onError: (error) => {
      if (createEmmPolicyService.errorHandling.require2SV(error)) {
        flashMessage({
          variant: 'error',
          title: <TwoStepVerificationErrorMessage />,
        });
        return;
      }
      if (createEmmPolicyService.errorHandling.resourceNotExist(error)) {
        flashMessage({ id: 'failed_to_create', variant: 'error', title: t('common:failed_to_create') });
        setTimeout(() => {
          router.push('/w/policy');
        }, 2000);
        return;
      }
      if (
        createEmmPolicyService.errorHandling.memberDisabled(error) ||
        createEmmPolicyService.errorHandling.notMatchAnyProduct(error) ||
        createEmmPolicyService.errorHandling.roleNotSupported(error) ||
        createEmmPolicyService.errorHandling.teamSeatExpired(error) ||
        createEmmPolicyService.errorHandling.userHasNoTeam(error)
      ) {
        flashMessage({ id: 'failed_to_create', variant: 'error', title: t('common:failed_to_create') });
        setTimeout(() => {
          window.location.href = '/';
        }, 2000);
        return;
      }
      // Unexpected error
      Sentry.captureException(error);
      flashMessage({ id: 'failed_to_create', variant: 'error', title: t('common:failed_to_create') });
    },
  });
};
