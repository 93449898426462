import { z } from 'zod';

import type { TeamKind } from '@/services/common/types';
import type { GranularControlKey } from '@/services/team/types';

import { SupportSessionKindInNumber } from '../serviceDesk/supportSession';
import { GranularControlRole } from '../teams/granular_controls/types';
import { roleSwitchUpdateKeys, seatPermissionKeys, settingKeys, teamPermissionKeys, updateKeys } from './constants';

export type SettingKey = (typeof settingKeys)[number];

export type UpdateKey = (typeof updateKeys)[number];
export type RoleSwitchUpdateKey = (typeof roleSwitchUpdateKeys)[number];

type ReadonlySettingType = Extract<SettingKey, 'granular_control' | 'scim'>;
export type EditableSettingType = Exclude<SettingKey, ReadonlySettingType>;

export type TeamPermissionsKey = (typeof teamPermissionKeys)[number];
export type TeamPermissions = Partial<Record<TeamPermissionsKey, boolean>>;
export type SeatPermissionsKey = (typeof seatPermissionKeys)[number];
export type SeatPermissions = Partial<Record<SeatPermissionsKey, boolean>>;

export type GetTeamSettingResponse = Partial<
  Record<
    TeamKind,
    {
      team_id: number;
      team_settings: TeamSettings;
      team_permissions: TeamPermissions;
      seat_permissions: SeatPermissions;
    }
  >
>;

type SessionRecordingSetting = {
  settings: {
    windows?: {
      storage_path: string | null;
      size: number;
    };
    mac?: {
      storage_path: string | null;
      size: number;
    };
    android?: {
      storage_path: string | null;
      size: number;
    };
  };
  windows: boolean;
  mac: boolean;
  android: boolean;
};
type WatermarkSetting = {
  settings: {
    text: string | null;
    client_name: boolean;
    client_user: boolean;
    client_public_ip: boolean;
    server_name: boolean;
    server_public_ip: boolean;
    font_size?: 'small' | 'medium' | 'large';
    font_color?: string;
    font_border_color?: string;
    layout_density?: 'low' | 'medium' | 'high';
  };
};
type IdleLogout = {
  available_statuses: [string, string, string, string, string];
};
type SessionNotification = {
  allow_mode_0: boolean;
};
export type MACAddressWhitelistSettings = {
  skip_already_authenticated_devices: boolean;
  skip_mobile_devices: boolean;
  skip_device_auth: boolean;
};
export type TeamSettings = Partial<Record<SettingKey, CommonSettingState>> & {
  schedule_time_zone?: CommonSettingState & {
    editable: boolean;
  };
  device_redirect?: CommonSettingState & {
    device_redirect_mode: {
      hid: boolean;
      printer: boolean;
      card_reader: boolean;
      user_added?: boolean;
    };
  };

  // session recording
  session_recording?: CommonSettingState & SessionRecordingSetting;
  sos_session_recording?: CommonSettingState & SessionRecordingSetting;

  // watermark
  watermark?: CommonSettingState & WatermarkSetting;
  sos_watermark?: CommonSettingState & WatermarkSetting;

  // idle logout
  client_idle_logout?: CommonSettingState & IdleLogout;
  pcp_idle_timeout?: CommonSettingState & IdleLogout;

  // session notification
  session_notification?: CommonSettingState & SessionNotification;
  file_session_notification?: CommonSettingState & SessionNotification;
  cmpt_session_notification?: CommonSettingState & SessionNotification;
  premium_session_notification?: CommonSettingState & SessionNotification;
  background_action_session_notification?: CommonSettingState & SessionNotification;
  sos_session_notification?: CommonSettingState & SessionNotification;
  sos_file_session_notification?: CommonSettingState & SessionNotification;
  sos_cmpt_session_notification?: CommonSettingState & SessionNotification;

  granular_control?: CommonSettingState & { available_features?: Array<GranularControlKey> };

  create_group_admin?: CommonSettingState & { has_group_admin: boolean; has_group_admin_privilege: boolean };

  macaddr_whitelist?: CommonSettingState & { settings: MACAddressWhitelistSettings };

  // relay recording access
  relay_recording_access?: CommonSettingState & RelayRecording;
  sos_relay_recording_access?: CommonSettingState & RelayRecording;

  // service desk
  service_desk_private_customer_survey?: CommonSettingState & {
    settings?: {
      sample_rate: number;
      ss_kind: Array<Extract<SupportSessionKindInNumber, 1 | 3>>;
    };
  };

  member_reboot_computer?: CommonSettingState & {
    member_reboot_computer_mode: {
      restart_streamer: boolean;
      normal_reboot: boolean;
      safe_mode_reboot: boolean;
    };
  };

  allow_src_version_control?: CommonSettingState & VersionControlSetting;
  allow_srs_version_control?: CommonSettingState & VersionControlSetting;

  device_auth_notify?: CommonSettingState & { device_auth_notify_mode: DeviceAuthenticationRoleSetting };
  device_auth_notify_browser?: CommonSettingState & { device_auth_notify_browser_mode: DeviceAuthenticationRoleSetting };
};

/**
 * NOTE: This type is depend on the feature toggle `PCP_506__TeamSettings_RoleManagement`.
 */
export type RoleSwitchSetting = Partial<Record<GranularControlRole, boolean>>;
export type RoleSwitchSettingKey = GranularControlRole;

export type CommonSettingState = {
  accessibility: 'read_only' | 'read_write' | 'hidden';
  value: string | boolean | number | null;
  mode?: string;
  /**
   * NOTE: This property is depend on the feature toggle `PCP_506__TeamSettings_RoleManagement`.
   */
  role_switch?: RoleSwitchSetting;
};

export type UpdateTeamNameResponse = {
  success: boolean;
};

export type UpdateSettingBody = {
  setting_type: UpdateKey;
  update_status?: any;
  additional_settings?: any;
};

export type UpdateSettingResponse = {
  setting_type: SettingKey;
  result: boolean;
};

export type VendorKey = 'service_now' | 'zendesk' | 'freshservice' | 'freshdesk' | 'jira' | 'salesforce' | 'ms_team';
export type VendorMetadata = {
  vendor_name: string;
  vendor_id: number;
  disabled_function: boolean;
  guide: string;
  vendor_human_name?: string;
  show_credential?: boolean;
};
export type VendorSetting = {
  vendor_id: number;
  team_id: number;
  credential: string;
  status: boolean;
  created_at: string;
  updated_at: string;
  sos_package_id: number;
};
export type VendorDataSet<Key extends string = VendorKey> = {
  vendor_settings: Record<Key, VendorMetadata>;
  team_vendor_settings: Array<VendorSetting>;
  sos_packages: Array<{ id: number; name: string }>;
};

export type ToggleVendorStatusResponse = {
  vendor_api_key: string;
  sos_package_id: number;
};

export type UpdateVendorApiKeyResponse = {
  vendor_api_key: string;
};

export type SetVendorSosPackageResponse = {
  sos_package_id: number;
};

export type SSOMethod = {
  id: number;
  name: string;
  status: number;
  protocol: number;
  idp_type: number;
  device_auth: boolean;
  default_sso: boolean;
  activated: boolean;
  note: string;
  jit: boolean;
  domain: Array<string> | null;
  settings: {
    client_id?: string;
    client_secret?: string;
    dynamic_signature_key_url?: string;
    idp_cert: string;
    idp_cert_fingerprint: string;
    idp_cert_fingerprint_algorithm: string;
    idp_entity_id: string;
    idp_sso_target_url: string;
    idp_token_url?: string;
    security: {
      digest_method: string;
      signature_method: string;
    };
  };
  login_url: string;
  // NOTE: There are other properties here but not in used.
};

export type SsoMethodList = Array<SSOMethod>;

export type CreateSsoMethodBody = {
  sso_name: string;
  sso_protocol: number;
  sso_idp_type: number;
  sso_domain_whitelist: string | null;
  sso_note: string | null;
  sso_idp_login_url: string;
  sso_jit: boolean | null;
  sso_idp_issuer: string | null;
  sso_certificate: string | null;
  client_id: string | null;
  client_secret: string | null;
  openid_token_url: string | null;
  dynamic_signature_key_url: string | null;
  signature_key: string | null;
};

export type CreateSsoMethodResponse = {
  xauth_method: {
    jit: boolean;
    login_url: string;
  };
};

export type SsoMethodData = {
  id: number;
  name: string;
  status: number;
  protocol: number;
  idp_type: number;
  device_auth: boolean;
  default_sso: boolean;
};

export type UpdateSsoMethodBody = {
  sso_name: string | null;
  sso_note: string | null;
  sso_certificate?: string | null;
  client_secret?: string | null;
};

export type UpdateSsoMethodResponse = {
  success: boolean;
  id: number;
  name: string;
};

export type DeleteSsoMethodResponse = {
  success: boolean;
};

export type UpdateSsoMethodStatusBody = {
  column: 'status' | 'device_auth';
  status: boolean;
};

export type UpdateSsoMethodStatusResponse = {
  success: boolean;
};

export type SetDefaultSsoMethodResponse = {
  success: boolean;
};

export type ResetDefaultSsoMethodResponse = {
  success: boolean;
};

export type ImportSsoMetadataResponse = {
  metadata: {
    idp_issuer: string;
    idp_login_url: string;
    certificate: string;
  };
};

export type ImportSsoMetadataUrl = {
  saml_url: string | null;
  openid_url: string | null;
};

export type ImportSsoMetadataUrlResponse = {
  metadata: {
    // SAML 2.0
    idp_issuer?: string;
    idp_login_url?: string;
    certificate?: string;

    // OpenID
    authorization_endpoint?: string;
    token_endpoint?: string;
    jwks_uri?: string;
  };
};

export type IPWhitelistKind = 'pcp' | 'src';

export type IpWhiteList = {
  max_num: number;
  lists: Array<string>;
};

export type GenerateSCIMTokenResponse = { token: string; token_hint: string };

export type GetTrustDeviceResponse = {
  trust_devices: Array<TrustedDevice> | null;
};

export type TrustedDevice = {
  id: number;
  dev_uuid: string;
  name: string;
  email: string;
  created_at: string;
  updated_at: string;
};

export type LimitedDevice = {
  id: number;
  name: string;
  version: string;
  email: string;
};

export type NotifyComputerKind =
  | 'srs_conn_notify_all_pcs'
  | 'srs_to_online_notify_all_pcs'
  | 'srs_to_offline_notify_all_pcs'
  | 'srs_disconn_notify_all_pcs';

export type GetNotifyComputerListResponse = {
  notify_all_computers_val: 'on' | 'off';
  /**
   * computer id
   */
  notify_computers: Array<number>;
  /**
   * group id
   */
  notify_tags: Array<number>;
};

export type UpdateNotifyComputerPayload = {
  notify_kind: NotifyComputerKind;
  notify_all_computers_val: boolean;
  computers: Array<number>;
  groups: Array<number>;
};

export type GetMACAddressListResponse = {
  max_num: number;
  macaddrs: Array<string>;
};

export type UpdateMACAddressListPayload = {
  /** 要新增的 mac addresses */
  add_macaddrs?: Array<string>;
  /** 要移除的 mac addresses */
  remove_macaddrs?: Array<string>;
  /** 此 team 所有的 mac addresses，若不在此陣列中的皆會移除（保留&新增) */
  replace_macaddrs?: Array<string>;
};

export type UpdateMACAddressListResponse = {
  /** 已存在重複的 mac addresses */
  duplicated_macaddrs: Array<string>;
};

export type RelayRecording = {
  /**
   * Unattended: '0', '1', '2'
   * Attended: '0', '1'
   */
  mode: string; // TODO: confirm property name
};

export const eventLogsModeEnumSchema = z.nativeEnum({
  adminsOwner: '1',
  adminsOwnerMembers: '2',
} as const);
export const eventLogsModeEnum = eventLogsModeEnumSchema.enum;
export type EventLogsMode = z.infer<typeof eventLogsModeEnumSchema>;

export type VersionControlPlatform = 'windows' | 'mac' | 'linux';
export type VersionControlPlatformSetting = {
  /**
   * If the value is `""`, it means selected the latest version.
   */
  version: string;
  status: 'pending' | 'active' | 'inactive' | null;
};
export type VersionControlSetting = {
  settings: Record<VersionControlPlatform, VersionControlPlatformSetting>;
};

export type DeviceAuthenticationRoleSetting = {
  device_user: boolean;
  owner: boolean;
  admins: boolean;
  super_admins: boolean;
};
