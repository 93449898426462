import { useContext } from 'react';

import { useAtomValue } from 'jotai';

import { featureControl } from '@/feature/toggle';
import { teamControlAtomFamily, teamControlScopeAtom } from '@/modules/TeamSettings/atoms';
import { TeamControlContext } from '@/modules/TeamSettings/contexts';
import { TeamControlContextValue } from '@/modules/TeamSettings/types';

/**
 * Get the team control context.
 */
export const useTeamControlContext = featureControl.getToggle('PCP_506__TeamSettings_RoleManagement')
  ? useTeamControlContext_new
  : useTeamControlContext_deprecated;

export function useTeamControlContext_new(): TeamControlContextValue {
  const teamControlScope = useAtomValue(teamControlScopeAtom);
  const teamControlAtom = teamControlAtomFamily(teamControlScope);

  return useAtomValue(teamControlAtom);
}

export function useTeamControlContext_deprecated(): TeamControlContextValue {
  return useContext(TeamControlContext);
}
