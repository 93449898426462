/**
 *  @see https://splashtop.atlassian.net/wiki/spaces/CS/pages/1176109287/User+Info+Query+API
 */
import axios from 'axios';
import { z } from 'zod';

import { TeamKind } from '@/models/TeamInformation/types';
import { isValidateDateTime } from '@/utils/datetime';

import { GranularControlKeys } from '../teams/team_members/granular_controls/utils';
import { checkResponse } from '../utils';

const method = 'POST';

const getUrl = () => '/api/web/v1/users/info_query';

const execute = <Q extends QueryMap, T extends TeamKind>(query: Q, teamKinds: Array<T>) => {
  return checkResponse(axios.request({ method, url: getUrl(), data: query }), generateSchema(query, teamKinds));
};

export const infoQueryService = {
  method,
  getUrl,
  execute,
} as const;

const LicenseInfoSchema = z
  .object({
    end_users: z.number(),
    technicians: z.number(),
    end_users_count: z.number(),
    technicians_count: z.number(),
    occupied_end_users_count: z.number(),
    occupied_technicians_count: z.number(),
    technician_required_granular_controls: z.array(
      z.union([
        z.literal(GranularControlKeys.attended_access),
        z.literal(GranularControlKeys.one_to_many),
        z.literal(GranularControlKeys.command_prompt),
      ]),
    ),
  })
  .nullable();

export function generateSchema<Q extends QueryMap, T extends TeamKind>(query: Q, teamKinds: ReadonlyArray<T>) {
  return z.custom<Record<T, TeamKindResult<Q>>>((data: any) => {
    return teamKinds.every((teamKind) => {
      const teamKindResult = data[teamKind];

      if (!teamKindResult) return false;

      let result = true;
      if (query.seat_permissions) {
        result =
          result &&
          teamKindResult.seat_permissions &&
          query.seat_permissions.every((seatPermission) => seatPermission in teamKindResult.seat_permissions);
      }

      if (query.team_permissions) {
        result =
          result &&
          teamKindResult.team_permissions &&
          query.team_permissions.every((teamPermission) => teamPermission in teamKindResult.team_permissions);
      }

      if (query.team_member_permissions) {
        result =
          result &&
          teamKindResult.team_member_permissions &&
          query.team_member_permissions.every((teamPermission) => teamPermission in teamKindResult.team_member_permissions);
      }

      if (query.team_info) {
        result = query.team_info.every((teamInfo) => {
          switch (teamInfo) {
            case 'license_info': {
              return LicenseInfoSchema.safeParse(teamKindResult.team_info.license_info).success;
            }
            case 'expires_at': {
              return isValidateDateTime(teamKindResult.team_info.expires_at);
            }
            case 'trial_premium_expired_at': {
              return (
                teamKindResult.team_info.trial_premium_expired_at === null ||
                isValidateDateTime(teamKindResult.team_info.trial_premium_expired_at)
              );
            }
            case 'trial_emm_expired_at': {
              return (
                teamKindResult.team_info.trial_emm_expired_at === null || isValidateDateTime(teamKindResult.team_info.trial_emm_expired_at)
              );
            }
            case 'is_expired':
            case 'upsell_premium':
            case 'can_upgrade_premium':
            case 'can_current_user_upgrade_premium':
            case 'can_trial_premium':
            case 'can_current_user_trial_premium':
            case 'upsell_emm':
            case 'can_buy_emm':
            case 'can_current_user_buy_emm':
            case 'can_trial_emm':
            case 'can_current_user_trial_emm':
              return teamInfo in teamKindResult.team_info;
            default: {
              const error: never = teamInfo;
              throw new Error(error);
            }
          }
        });
      }

      return result;
    });
  });
}

// TODO: Complete the query type
type QueryMap = {
  seat_permissions?: ReadonlyArray<SeatPermissionKey>;
  team_permissions?: ReadonlyArray<TeamPermissionKey>;
  team_member_permissions?: ReadonlyArray<TeamMemberPermissionKey>;
  team_info?: ReadonlyArray<TeamInfoKey>;
};

type SeatPermissionKey = 'premier_pack' | 'granular_control' | 'license_type';
type TeamPermissionKey = 'premier_pack' | 'preference_policy' | 'create_group_admin';
type TeamMemberPermissionKey =
  | 'user_management'
  | 'grant_granular_control'
  | 'license_type'
  | 'invite_member'
  | 'create_group_admin'
  | 'emm_patch';
type TeamInfoKey =
  | 'license_info'
  | 'expires_at'
  | 'is_expired'
  // Premium upsell related
  /** Whether should upsell premium or not */
  | 'upsell_premium'
  /** Whether the current team can upgrade premium or not */
  | 'can_upgrade_premium'
  /** Whether the current user can upgrade premiun or not */
  | 'can_current_user_upgrade_premium'
  /** Whether the current team can trial premium or not */
  | 'can_trial_premium'
  /** Whether the current user can trial premium or not */
  | 'can_current_user_trial_premium'
  /** The expired time to premium, format: `yyyy-mm-dd hh:mm:ss` */
  | 'trial_premium_expired_at'
  // Emm upsell related
  /** Whether should upsell emm or not */
  | 'upsell_emm'
  | 'can_buy_emm'
  | 'can_current_user_buy_emm'
  | 'can_trial_emm'
  | 'can_current_user_trial_emm'
  | 'trial_emm_expired_at';

type TeamKindResult<Q extends QueryMap> = SeatPermissionResult<Q> &
  TeamPermissionResult<Q> &
  TeamMemberPermissionResult<Q> &
  TeamInfoResult<Q>;

type SeatPermissionResult<Q extends QueryMap> = Q['seat_permissions'] extends Array<SeatPermissionKey>
  ? { seat_permissions: Record<Q['seat_permissions'][number], boolean> }
  : { seat_permissions?: never };

type TeamPermissionResult<Q extends QueryMap> = Q['team_permissions'] extends Array<TeamPermissionKey>
  ? { team_permissions: Record<Q['team_permissions'][number], boolean> }
  : { team_permissions?: never };

type TeamMemberPermissionResult<Q extends QueryMap> = Q['team_member_permissions'] extends Array<TeamMemberPermissionKey>
  ? { team_member_permissions: Record<Q['team_member_permissions'][number], boolean> }
  : { team_member_permissions?: never };

type TeamInfoResult<Q extends QueryMap> = Q['team_info'] extends Array<TeamInfoKey>
  ? {
      team_info: Record<
        Q['team_info'][number],
        Q['team_info'][number] extends 'license_info'
          ? z.infer<typeof LicenseInfoSchema>
          : Q['team_info'][number] extends 'expires_at'
          ? string
          : Q['team_info'][number] extends 'trial_premium_expired_at' | 'trial_emm_expired_at'
          ? null | string
          : boolean
      >;
    }
  : { team_info?: never };
