import * as Sentry from '@sentry/nextjs';
import { useMutation } from '@tanstack/react-query';
import { Draft } from 'immer';
import { useSetAtom } from 'jotai';
import { useTranslation } from 'react-i18next';

import { EditableSettingType, TeamSettings, UpdateKey, UpdateSettingBody } from '@/services/teamSettings';
import { updateTeamSettingsService } from '@/services/teams/team_settings';

import { queryMainKey } from '../constants';
import { showErrorMessageOnRailsPage } from '../utils';
import { useTeamControlContext } from './useTeamControlContext';
import { useTeamMetadata } from './useTeamMetadata';

type StateMutationItem<SKey extends EditableSettingType, UKey extends UpdateKey> = {
  settingKey: SKey;
  updateKey: UKey;
  value: UpdateSettingBody['update_status'];
  additionalSettings?: UpdateSettingBody['additional_settings'];
  /**
   * Custom function to update the state. This is used with immer.
   *
   * If this is not provided, will just update the value of the state by default.
   *
   * @param stateMapDraft - The draft of the whole state map.
   */
  updateStateFn?: (stateMapDraft: Draft<TeamSettings>) => void;
};

type FeatureStateMutationPayload<SKey extends EditableSettingType = EditableSettingType, UKey extends UpdateKey = UpdateKey> = Array<
  StateMutationItem<SKey, UKey>
>;

/**
 * Mutation hook to update the feature state.
 *
 * This will trigger to showing the loading spinner while the mutation is in progress.
 */
export function useFeatureStateMutation() {
  const { t } = useTranslation();

  const teamMetadata = useTeamMetadata();

  const { atoms } = useTeamControlContext();
  const dispatch = useSetAtom(atoms.teamSettingsAtom);

  return useMutation(
    async (items: FeatureStateMutationPayload) => {
      const payload = items.map(({ updateKey, value, additionalSettings }) => ({
        setting_type: updateKey,
        update_status: value,
        additional_settings: additionalSettings,
      }));
      return updateTeamSettingsService.execute(teamMetadata.team_id, payload);
    },
    {
      mutationKey: [queryMainKey, 'mutateSetting'],
      onMutate: (items: FeatureStateMutationPayload) => {
        dispatch({
          type: 'update',
          updateDraft: (draft) => {
            items.forEach(({ settingKey, value, updateStateFn }) => {
              if (updateStateFn) {
                updateStateFn(draft);
                return;
              }

              const setting = draft[settingKey];
              if (setting) {
                setting.value = value;
              }
            });
          },
        });
      },
      onSuccess: () => {
        dispatch({ type: 'save' });
      },
      onError: (error) => {
        Sentry.captureException(error);
        showErrorMessageOnRailsPage(t('common:unexpectedError'));
        dispatch({ type: 'rollback' });
      },
    },
  );
}
