import { z } from 'zod';

import { granularControlDetailSettingKeys, granularControlKeys } from './constants';

/**
 * - `manager`: Admin
 * - `member`: Member
 * - `group_admin`: Group Admin // NOTE: This type is depend on the feature toggle `PCP_506__TeamSettings_RoleManagement`.
 */
export const granularControlRoleSchema = z.enum(['manager', 'member', 'group_admin']);
export const granularControlRoleEnum = granularControlRoleSchema.enum;
export type GranularControlRole = z.infer<typeof granularControlRoleSchema>;

export const granularControlSettingValueSchema = z.enum(['on', 'off']);
export const granularControlSettingSchema = createGranularControlSettingSchema(granularControlSettingValueSchema);
export type GranularControlSetting = z.infer<typeof granularControlSettingSchema>;

export const granularControlKeySchema = z.enum(granularControlKeys);
export const granularControlKeyEnum = granularControlKeySchema.enum;
export type GranularControlKey = z.infer<typeof granularControlKeySchema>;

export const granularControlDetailSettingKeySchema = z.enum(granularControlDetailSettingKeys);
export const granularControlDetailSettingKeyEnum = granularControlDetailSettingKeySchema.enum;
export type GranularControlDetailSettingKey = z.infer<typeof granularControlDetailSettingKeySchema>;
export const granularControlDetailSettingSchema = z
  .object({
    [granularControlDetailSettingKeyEnum.conn_perm_setting]: createGranularControlSettingSchema(
      z.object({
        setting: z.union([z.string(), z.literal(0), z.literal(1), z.literal(2)]),
        option: z.union([z.string(), z.null(), z.literal(1), z.literal(2)]),
      }),
    ),
  })
  .partial();
export type GranularControlDetailSetting = z.infer<typeof granularControlDetailSettingSchema>;

export const granularControlMapSchema = z
  .object({
    [granularControlKeyEnum.attended_access]: granularControlSettingSchema,
    [granularControlKeyEnum.command_prompt]: granularControlSettingSchema,
    [granularControlKeyEnum.copy_paste]: granularControlSettingSchema,
    [granularControlKeyEnum.file]: granularControlSettingSchema,
    [granularControlKeyEnum.one_to_many]: granularControlSettingSchema,
    [granularControlKeyEnum.remote_control]: granularControlSettingSchema,
    [granularControlKeyEnum.remote_print]: granularControlSettingSchema,
    [granularControlKeyEnum.required_2sv]: granularControlSettingSchema,
    [granularControlKeyEnum.watermark]: granularControlSettingSchema,
    [granularControlKeyEnum.conn_perm]: granularControlSettingSchema,
    [granularControlKeyEnum.relay_recording]: granularControlSettingSchema,
    [granularControlKeyEnum.sos_relay_recording]: granularControlSettingSchema,
  })
  .partial()
  .and(granularControlDetailSettingSchema);
export type GranularControlMap = z.infer<typeof granularControlMapSchema>;

function createGranularControlSettingSchema<Schema extends z.ZodTypeAny>(settingValueSchema: Schema) {
  return z.record(granularControlRoleSchema, settingValueSchema);
}
