import { useCallback, useEffect, useState } from 'react';

import { featureControl } from '@/feature/toggle';
import type { QuickSetupInfo } from '@/modules/Welcome/types';
import { getQuickSetupInfo as getQuickSetupInfoAPI } from '@/services/common';
import useTeamInformation from '@/utils/useTeamInformation';

export const useAddComputerHandler = () => {
  const teamInformation = useTeamInformation();
  const [quickSetupInfo, setQuickSetupInfo] = useState<QuickSetupInfo>({
    SRSDownloadInstruction: '',
    SRSDownloadKind: '',
    SRSDownloadLink: '',
    SRCLaunchURI: '',
  });

  const isSBAMemberWithInstruction =
    teamInformation?.currentTeam === 'sba' &&
    teamInformation?.role === 'member' &&
    quickSetupInfo.SRSDownloadKind === 'none' &&
    quickSetupInfo.SRSDownloadInstruction !== '';

  // BE still see `splashtop` and `ste_custom` as `srs` in BE logic in 3720
  const isSRSBased =
    teamInformation?.currentTeam === 'srs' || teamInformation?.currentTeam === 'splashtop' || teamInformation?.currentTeam === 'ste_custom';
  const isSRSMemberWithInstruction =
    (featureControl.getToggle('PCP_2537__Unified_Product_Hide_Oobe_Add_Computer_Button')
      ? isSRSBased
      : teamInformation?.currentTeam === 'srs') && teamInformation?.role === 'member';

  const isShowInstruction = isSBAMemberWithInstruction || isSRSMemberWithInstruction;

  const isMember = teamInformation?.role === 'member';

  const handleClickAddComputer = useCallback(() => {
    if (teamInformation) {
      const { SRSDownloadKind, SRSDownloadLink } = quickSetupInfo;
      const { role, groupScope, currentTeam } = teamInformation;

      if (role !== 'member' && groupScope === 'all') {
        if (window.top) window.top.location.href = SRSDownloadLink;
      } else if (role !== 'member' && groupScope === 'part') {
        if (window.top) window.top.location.href = '/team_deployment';
      } else if (role === 'member' && currentTeam === 'sba' && SRSDownloadKind !== 'none') {
        const newWindow = window.open(SRSDownloadLink, '_blank', 'noopener,noreferrer');
        if (newWindow) newWindow.opener = null;
      }
    } else {
      if (window.top) window.top.location.href = '/login';
    }
  }, [quickSetupInfo, teamInformation]);

  useEffect(() => {
    getQuickSetupInfoAPI()
      .then((res) => {
        setQuickSetupInfo({
          SRSDownloadInstruction: res.instruction,
          SRSDownloadKind: res.srs_download_kind,
          SRSDownloadLink: res.srs_download_link,
          SRCLaunchURI: res.src_launch_uri,
        });
      })
      .catch(console.error);
  }, []);

  return { isShowInstruction, isMember, handleClickAddComputer };
};
