import { RoleSwitchContextValue } from '@/modules/TeamSettings/components/RoleSwitch/types';

import { useFeatureState } from './useFeatureState';

/**
 * Provides the context for the role switch components.
 */
export function useRoleSwitchContext(): Omit<RoleSwitchContextValue, 'readMode'> {
  const createGroupAdminState = useFeatureState('create_group_admin');

  const showGroupAdmin = createGroupAdminState?.value === true;

  return {
    showGroupAdmin,
  };
}
