import { Dictionary } from 'lodash';
import { z } from 'zod';

import { autoUpdatePolicyOptionMap, osPatchPolicyItemConfig } from '@/services/teams/emm_policies/os_patch/constants';

import { createPolicyItemSchema } from '../types';

const enablePatchPolicySchema = createPolicyItemSchema(osPatchPolicyItemConfig.enable_policy.code, z.boolean());
type EnablePatchPolicy = z.infer<typeof enablePatchPolicySchema>;

const autoUpdatePolicyOptionSchema = z.union([
  z.literal(autoUpdatePolicyOptionMap.auto),
  z.literal(autoUpdatePolicyOptionMap.download_updates),
  z.literal(autoUpdatePolicyOptionMap.check_updates),
]);
//type _AutoUpdatePolicyOption = z.infer<typeof autoUpdatePolicyOptionSchema>;

// const autoUpdatePolicyOptionListSchema = z.array(autoUpdatePolicyOptionSchema);
// type _AutoUpdatePolicyOptionList = z.infer<typeof autoUpdatePolicyOptionListSchema>;

const autoUpdatePolicySchema = createPolicyItemSchema(osPatchPolicyItemConfig.auto_update_policy.code, autoUpdatePolicyOptionSchema);
export type AutoUpdatePolicy = z.infer<typeof autoUpdatePolicySchema>;

const makeUpScanSchema = createPolicyItemSchema(osPatchPolicyItemConfig.make_up_scan.code, z.boolean());
type MakeUpScan = z.infer<typeof makeUpScanSchema>;

const scanScheduleSchema = createPolicyItemSchema(
  osPatchPolicyItemConfig.scan_schedule.code,
  z.object({ interval: z.number().max(9999999).min(0), time: z.string() }),
);
type ScanSchedule = z.infer<typeof scanScheduleSchema>;

// For policy item with fixed code
export const policyItemSchema = z.discriminatedUnion('code', [
  enablePatchPolicySchema,
  autoUpdatePolicySchema,
  scanScheduleSchema,
  makeUpScanSchema,
]);

export type osPatchPolicyFixedCodeItem = z.infer<typeof policyItemSchema>;
export type PolicyItem = osPatchPolicyFixedCodeItem;
export type PolicyItemWithoutId = Omit<PolicyItem, 'id'>;

export const policyNodeIdsSchema = z.object({
  emm_policy_id: z.number(),
  parent_id: z.union([z.number(), z.null()]),
});

export const policyNodeSchema = policyNodeIdsSchema.merge(
  z.object({
    policy_items: z.array(policyItemSchema),
  }),
);
export type PolicyNode = z.infer<typeof policyNodeSchema>;

export type osPatchPolicyCodeMapItem = {
  [osPatchPolicyItemConfig.enable_policy.code]: EnablePatchPolicy;
  [osPatchPolicyItemConfig.auto_update_policy.code]: AutoUpdatePolicy;
  [osPatchPolicyItemConfig.scan_schedule.code]: ScanSchedule;
  [osPatchPolicyItemConfig.make_up_scan.code]: MakeUpScan;
};

// @doc `null` represents current no rule for this policy item
export type PolicyCodeRuleMapItem = {
  [osPatchPolicyItemConfig.enable_policy.code]: null;
  [osPatchPolicyItemConfig.auto_update_policy.code]: null;
  [osPatchPolicyItemConfig.scan_schedule.code]: {
    interval: {
      maximum: 1800;
      minimum: 0;
    };
  };
  [osPatchPolicyItemConfig.make_up_scan.code]: null;
};

export type PolicyItemInheritedStatus<K extends keyof osPatchPolicyCodeMapItem> = {
  shouldDelete: boolean;
  isOverridden: boolean;
  currentPolicyItem: Dictionary<PolicyItem>[K] | null;
  parentPolicyItem: Dictionary<PolicyItem>[K] | null;
  displayValue: osPatchPolicyCodeMapItem[K]['value'];
  parentValue: osPatchPolicyCodeMapItem[K]['value'];
};
