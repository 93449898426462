import { featureControl } from '@/feature/toggle';
import { useTeamRole, useUnattendedTeamInformation } from '@/models/TeamInformation';
import { TeamKind } from '@/services/common/types';

export const useEmmPolicyAccess = () => {
  const unattendedTeamInformation = useUnattendedTeamInformation();
  const teamRole = useTeamRole();

  const availableTeamKinds: Array<TeamKind> = featureControl.getToggle('PCP_2681__Policy__unified__access_endpoint_policy_page')
    ? ['sba', 'srs', 'msp', 'splashtop', 'ste_custom']
    : ['sba', 'srs', 'msp'];

  const isTeamSupported = unattendedTeamInformation && availableTeamKinds.includes(unattendedTeamInformation.teamKind);
  const isRoleSupported = teamRole.isOwner || teamRole.isAdmin;

  return featureControl.getToggle('PCP_1134__Policy_management') && Boolean(isTeamSupported && isRoleSupported);
};
