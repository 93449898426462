import { useCallback, useEffect, useLayoutEffect, useState } from 'react';

import { Center, Collapse, HStack, Stack } from '@chakra-ui/react';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import groupBy from 'lodash/groupBy';
import keyBy from 'lodash/keyBy';
import partition from 'lodash/partition';
import { Trans, useTranslation } from 'react-i18next';

import { DEFAULT_GROUP_ID } from '@/components/ComputerList/utils';
import { POLICY_SUPPORT_ARTICLE } from '@/constants';
import {
  RippleBanner,
  RippleComputerAccordionButton,
  RippleComputerSearchBar,
  RippleFilter,
  RippleHyperLink,
  RippleListItem,
  RippleSheetModal,
  RippleStrong,
  RippleTypography,
  RippleUnorderedList,
  useRippleFlashMessage,
} from '@/design';
import { featureControl } from '@/feature/toggle';
import { useTeamExpiration } from '@/models/TeamInformation';
import { PLATFORMS, Policy } from '@/services/teams/emm_policies';
import { getOSType } from '@/utils/computers';
import { truthy } from '@/utils/truthy';

import { assignPolicyResultAtom } from '../../atoms';
import {
  useAssignPolicyMutation,
  useComputerListQuery,
  useGroupListQuery,
  useGroupPolicyListQuery,
  useOsPatchEnabledCheckQuery,
  usePoliciesQuery,
  usePolicyRelationModel,
  useServerPolicyListQuery,
  useSoftwarePatchEnabledCheckQuery,
} from '../../hooks';
import { getPlatformString, isPreparedDefaultPolicy } from '../../utils';
import {
  isAllGroupsExpandedAtom,
  isAllGroupsSelectedAtom,
  selectedGroupMapAtom,
  selectedServerMapAtom,
  toggleAllGroupsExpandedAtom,
} from './atoms';
import { Footer, GroupTable, TableFilter, useTableFilter } from './components';

type AssignPolicyModalProps = {
  isOpen: boolean;
  isLoading: boolean;
  teamPolicy: Policy | null;
  currentPolicy: Policy | null;
  groupIds: Array<number>;
  onClose: () => void;
  onAssignFailed: () => void;
};

export const AssignPolicyModal = ({
  isOpen,
  isLoading,
  teamPolicy,
  currentPolicy,
  groupIds,
  onClose,
  onAssignFailed,
}: AssignPolicyModalProps) => {
  const [keyword, setKeyword] = useState<string>('');
  const [canTableRender, setCanTableRender] = useState(false);
  const { flashMessage } = useRippleFlashMessage();
  const setAssignPolicyResult = useSetAtom(assignPolicyResultAtom);
  const { isTeamExpired } = useTeamExpiration();
  const { t } = useTranslation();

  const policyMap = usePoliciesQuery({
    select: (data) => {
      const filteredByPlatform = data.filter((policy) => policy.policy_kind === 'policy' && policy.platform === currentPolicy?.platform);
      return keyBy(filteredByPlatform, 'id');
    },
  });
  const { groupPolicyRelation, serverPolicyRelation } = usePolicyRelationModel();
  const computerListQuery = useComputerListQuery({
    select: (data) => {
      const supportEmmPolicy = data.filter((computer) => computer.support_emm_policy);
      const computerWithSamePlatform = supportEmmPolicy.filter((computer) => {
        switch (currentPolicy?.platform) {
          case 'Windows':
            return getOSType(computer.version).type === 'win';
          case 'macOS':
            return getOSType(computer.version).type === 'mac';
          case 'Android': {
            if (!featureControl.getToggle('PCP_2647__Policy_support_android_platform')) {
              return false;
            }
            return getOSType(computer.version).type === 'android';
          }
          default:
            return false;
        }
      });
      return {
        list: computerWithSamePlatform,
        groupByGroupId: groupBy(computerWithSamePlatform, (data) => data.group_id),
        keyByComputerId: keyBy(computerWithSamePlatform, (data) => data.id),
      };
    },
    enabled: isOpen && !isTeamExpired,
  });
  const groupMapQuery = useGroupListQuery({
    select: (data) => keyBy(data, (group) => group.id),
    enabled: isOpen && !isTeamExpired,
  });
  const serverPolicyMapQuery = useServerPolicyListQuery({
    select: (data) => keyBy(data, 'server_id') ?? {},
    enabled: isOpen && !isTeamExpired,
  });
  const groupPolicyMapQuery = useGroupPolicyListQuery({
    select: (data) =>
      data.reduce(
        (acc, { group_id, emm_policy_id }) => {
          const policyPlatform = policyMap.data?.[emm_policy_id]?.platform;
          if (!policyPlatform) {
            return acc;
          }
          acc[group_id] = { ...acc[group_id], [policyPlatform]: emm_policy_id };
          return acc;
        },
        {} as Record<number, { [PLATFORMS.macOS]: number; [PLATFORMS.Windows]: number; [PLATFORMS.Android]: number }>,
      ),
    enabled: isOpen && !isTeamExpired,
  });
  const isSoftwarePatchEnabled = useSoftwarePatchEnabledCheckQuery({
    policyId: currentPolicy?.id,
    enabled: isOpen && !isTeamExpired && featureControl.getToggle('PCP_1391__Policy_software_patch'),
  }).data;
  const isOsPatchEnabled = useOsPatchEnabledCheckQuery({
    policyId: currentPolicy?.id,
    enabled: isOpen && !isTeamExpired && featureControl.getToggle('PCP_1682__Policy_os_patch'),
  }).data;

  const isAllGroupSelected = useAtomValue(isAllGroupsSelectedAtom);
  const isAllGroupsExpanded = useAtomValue(isAllGroupsExpandedAtom);
  const toggleAllGroupsExpanded = useSetAtom(toggleAllGroupsExpandedAtom);
  const [selectedGroupMap, setSelectedGroupMap] = useAtom(selectedGroupMapAtom);
  const [selectedServerMap, setSelectedServerMap] = useAtom(selectedServerMapAtom);
  const { filterCount, resetAllFilter } = useTableFilter();

  const selectedGroupCount = Object.values(selectedGroupMap).filter(Boolean).length;
  const selectedServerCount = Object.values(selectedServerMap).filter(Boolean).length;

  const assignPolicyMutation = useAssignPolicyMutation({
    onSuccess: (res) => {
      if (!res) {
        // Invalid policy
        handleModalClose();
        return;
      }
      if (res.groups.fail.length > 0 || res.servers.fail.length > 0) {
        setAssignPolicyResult({
          groups: {
            success: res.groups.success.map((groupId) => (currentPolicy ? groupPolicyRelation?.[groupId].group : undefined)).filter(truthy),
            fail: res.groups.fail.map((groupId) => (currentPolicy ? groupPolicyRelation?.[groupId].group : undefined)).filter(truthy),
          },
          computers: {
            success: res.servers.success.map((serverId) => serverPolicyRelation?.[serverId].server).filter(truthy),
            fail: res.servers.fail.map((serverId) => serverPolicyRelation?.[serverId].server).filter(truthy),
          },
        });
        onAssignFailed();
        return;
      }
      handleModalClose();
      flashMessage({ id: 'assigned-successfully', variant: 'success', title: t('common:assigned_successfully') });
    },
  });

  // When selecting a group, all servers in the group should be selected except for the servers that have their own policy, even if the policy is the same as the group's policy
  const handleSelectedGroupChange = (groupId: number) => {
    setSelectedGroupMap((prev) => {
      const isGroupSelected = prev[groupId];
      return { ...prev, [groupId]: !isGroupSelected };
    });
    const serversToFollowGroup = computerListQuery.data?.groupByGroupId[groupId]?.reduce(
      (acc, computer) => {
        // Keep the selected state of the servers that have their own policy (has a record in database)
        if (selectedServerMap[computer.id] && serverPolicyRelation?.[computer.id].policy.id === currentPolicy?.id) {
          acc[computer.id] = true;
          return acc;
        }
        // Unselect all local state to follow the group except for the servers that have their own policy
        acc[computer.id] = false;
        return acc;
      },
      {} as Record<number, boolean>,
    );
    setSelectedServerMap((prev) => ({ ...prev, ...serversToFollowGroup }));
  };

  const handleSelectedServerChange = (serverId: number) => {
    setSelectedServerMap((prev) => ({ ...prev, [serverId]: !prev[serverId] }));
  };

  const handleAllGroupSelect = () => {
    setSelectedGroupMap(
      groupIds
        .filter((id) => id !== Number(DEFAULT_GROUP_ID))
        .reduce(
          (acc, id) => {
            acc[id] = !isAllGroupSelected;
            return acc;
          },
          {} as Record<number, boolean>,
        ),
    );
  };

  const handleModalClose = useCallback(() => {
    onClose();
  }, [onClose]);

  const handleAssign = () => {
    if (isTeamExpired) {
      return;
    }
    if (!currentPolicy || isPreparedDefaultPolicy(currentPolicy.id)) {
      return;
    }
    const platform = currentPolicy.platform;
    const [selectedGroups, unselectedGroups] = partition(Object.entries(selectedGroupMap), ([, isSelected]) => isSelected);
    const [selectedServers, unselectedServers] = partition(Object.entries(selectedServerMap), ([, isSelected]) => isSelected);
    const groupsToAssign = selectedGroups
      .map(([groupId]) => Number(groupId))
      .filter((groupId) => groupPolicyRelation?.[groupId].policies[platform].policy.id !== currentPolicy.id);
    const groupsToUnassign = unselectedGroups
      .map(([groupId]) => Number(groupId))
      .filter(
        (groupId) =>
          // if the group is following the team policy, no need to unassign
          !groupPolicyRelation?.[groupId].policies[platform].isFollowTeam &&
          groupPolicyRelation?.[groupId].policies[platform].policy.id === currentPolicy.id,
      );
    const serversToAssign = selectedServers
      .map(([serverId]) => Number(serverId))
      .filter((serverId) => serverPolicyRelation?.[serverId].policy.id !== currentPolicy.id);
    const serversToUnassign = unselectedServers
      .map(([serverId]) => Number(serverId))
      .filter(
        // if the server is following the group policy, no need to unassign
        (serverId) => !serverPolicyRelation?.[serverId].isFollowGroup && serverPolicyRelation?.[serverId].policy.id === currentPolicy.id,
      );
    // When assigning the team default policy, the actual action is unassign the policy from the group
    if (currentPolicy.super_root) {
      assignPolicyMutation.mutate({
        policyId: currentPolicy.id,
        platform: currentPolicy.platform,
        groupsToAssign: [],
        groupsToUnassign: [],
        serversToAssign,
        serversToUnassign,
        groupsToUnassignByPlatform: groupsToAssign,
      });
      return;
    }
    assignPolicyMutation.mutate({
      policyId: currentPolicy.id,
      platform: currentPolicy.platform,
      groupsToAssign,
      groupsToUnassign,
      serversToAssign,
      serversToUnassign,
      groupsToUnassignByPlatform: [],
    });
  };

  useEffect(
    function initSelectedPolicy() {
      const selectedGroupMap = currentPolicy
        ? Object.values(groupPolicyRelation ?? {}).reduce(
            (acc, group) => {
              acc[group.group.id] = group.policies[currentPolicy.platform].policy.id === currentPolicy.id;
              return acc;
            },
            {} as Record<number, boolean>,
          )
        : {};

      setSelectedGroupMap(selectedGroupMap);

      const selectedServerMap = currentPolicy
        ? Object.values(serverPolicyRelation ?? {}).reduce(
            (acc, server) => {
              acc[server.server.id] =
                !server.isFollowGroup && server.platform === currentPolicy.platform && server.policy.id === currentPolicy.id;
              return acc;
            },
            {} as Record<number, boolean>,
          )
        : {};
      setSelectedServerMap(selectedServerMap);
    },
    [currentPolicy, groupPolicyRelation, serverPolicyRelation, setSelectedGroupMap, setSelectedServerMap],
  );

  useEffect(
    function handleServerPolicyListQueryError() {
      if (serverPolicyMapQuery.error) {
        flashMessage({ id: 'server-policy-list-query', variant: 'error', title: t('common:failed_to_get_data') });
      }
    },
    [flashMessage, serverPolicyMapQuery.error, t],
  );
  useEffect(
    function handleGroupPolicyListQueryError() {
      if (groupPolicyMapQuery.error) {
        flashMessage({ id: 'group-policy-list-query', variant: 'error', title: t('common:failed_to_get_data') });
      }
    },
    [flashMessage, groupPolicyMapQuery.error, t],
  );

  useLayoutEffect(
    function deterTableRender() {
      if (isOpen) {
        setTimeout(() => {
          setCanTableRender(true);
        }, 1800);
      }
      return () => {
        setCanTableRender(false);
      };
    },
    [isOpen],
  );

  return (
    <RippleSheetModal
      data-testid="assign-policy"
      title={t('emm-policy:assign_policy_name', { policy_name: currentPolicy?.name })}
      subtitle={getPlatformString(currentPolicy?.platform ?? '')}
      footer={<Footer onClose={handleModalClose} onAssign={handleAssign} isLoading={assignPolicyMutation.isLoading} />}
      isOpen={isOpen}
      onClose={handleModalClose}
    >
      <RippleUnorderedList mb="24px">
        <RippleListItem>
          <RippleTypography variant="body02">
            <Trans
              t={t}
              i18nKey="emm-policy:streamer_preference_configured_in_the_new_policy_will_take_priority_over_the_legacy_preference_policy"
              components={{
                Link: <RippleHyperLink variant="hyperlink02" target="_blank" href={POLICY_SUPPORT_ARTICLE} />,
              }}
            />
          </RippleTypography>
        </RippleListItem>
        <RippleListItem>
          <RippleTypography variant="body02" mb="24px">
            {t('emm-policy:computers_policy_overrides_will_be_cleared_after_policy_assignment')}
          </RippleTypography>
        </RippleListItem>
      </RippleUnorderedList>
      <Collapse in={filterCount > 0} animateOpacity>
        {/* Put the bottom gap to the element's height to prevent the box-shadow from being clipped by <Collapse /> */}
        <Center alignItems="flex-start" height="76px">
          <RippleBanner
            variant="default"
            icon={<RippleFilter color="blue.200" isApplied={filterCount > 0} />}
            button={{ name: t('common:show_all'), onClick: resetAllFilter }}
          >
            {t('common:filters_have_been_applied')}
          </RippleBanner>
        </Center>
      </Collapse>
      <Stack gap="8px" maxH="100%" pb="32px">
        <HStack justifyContent="space-between">
          <RippleTypography variant="body02">
            <Trans
              t={t}
              i18nKey="emm-policy:number_groups_number_computers_selected"
              values={{ group_count: selectedGroupCount, computer_count: selectedServerCount }}
              components={{
                Strong: <RippleStrong variant="strong02" />,
              }}
            />
          </RippleTypography>
          <HStack gap="4px">
            {/* TODO: support shortcut */}
            <RippleComputerAccordionButton isEnabled={isAllGroupsExpanded} onClick={toggleAllGroupsExpanded} />
            <TableFilter groupMap={groupMapQuery.data ?? {}} policyMap={policyMap.data ?? {}} />
            <RippleComputerSearchBar data-testid="search" initKeyword={keyword} onSearch={(keyword) => setKeyword(keyword.toLowerCase())} />
          </HStack>
        </HStack>
        {currentPolicy && teamPolicy && groupPolicyRelation && serverPolicyRelation && (
          <GroupTable
            keyword={keyword}
            isLoading={!canTableRender || isLoading}
            groupIds={groupIds}
            groupPolicyRelation={groupPolicyRelation}
            serverPolicyRelation={serverPolicyRelation}
            currentPolicy={currentPolicy}
            defaultPolicy={teamPolicy}
            selectedGroups={selectedGroupMap}
            selectedServers={selectedServerMap}
            isAllGroupsSelected={isAllGroupSelected}
            isOsPatchEnabled={isOsPatchEnabled}
            isSoftwarePatchEnabled={isSoftwarePatchEnabled}
            onSelectedGroupChange={handleSelectedGroupChange}
            onSelectedServerChange={handleSelectedServerChange}
            onAllGroupSelect={handleAllGroupSelect}
          />
        )}
      </Stack>
    </RippleSheetModal>
  );
};
