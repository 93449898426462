import { atom } from 'jotai';
import { atomFamily } from 'jotai/utils';

import { teamInformationMapAtom } from '@/models/TeamInformation';
import { TeamControlContextValue, TeamControlScope } from '@/modules/TeamSettings/types';
import { getPlanName } from '@/modules/TeamSettings/utils';

import { createCacheAtom } from './atoms';
import { settingControlAtom } from './settingControlAtom';

/**
 * Store the scope of teamControlAtomFamily
 */
export const teamControlScopeAtom = atom<TeamControlScope>({ teamId: NaN, teamKind: 'srs' });

export const teamControlAtomFamily = atomFamily(
  ({ teamKind }: TeamControlScope) =>
    atom<TeamControlContextValue>((get) => {
      const teamInformationMap = get(teamInformationMapAtom);
      const { teamSettingsSet: teamDataSet, granularControl } = get(settingControlAtom);

      const teamMetadata = teamInformationMap[teamKind];
      const teamData = teamDataSet[teamKind];

      if (teamMetadata === undefined || teamData === undefined) {
        throw new Error('Team metadata or settings is not found') as never;
      }

      const teamSettings = teamData.team_settings;
      const teamPermissions = teamData.team_permissions;
      const seatPermissions = teamData.seat_permissions;

      const teamMetadataAtom = atom(teamMetadata);
      const teamSettingsAtom = createCacheAtom({ data: teamSettings });
      const granularControlAtom = createCacheAtom({ data: granularControl });

      return {
        teamKind,
        teamPermissions,
        seatPermissions,
        planName: getPlanName(teamKind, teamMetadata) ?? '',
        atoms: {
          teamMetadataAtom,
          teamSettingsAtom,
          granularControlAtom,
        },
      };
    }),
  (a, b) => {
    return a.teamId === b.teamId && a.teamKind === b.teamKind;
  },
);
