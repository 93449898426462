import Head from 'next/head';
import { useRef } from 'react';

import { Box } from '@chakra-ui/react';
import { useSize } from '@chakra-ui/react-use-size';
import { Global, css } from '@emotion/react';
import { useAtomValue } from 'jotai';
import { useTranslation } from 'next-i18next';

import colors from '@/design/theme/colors';
import { featureControl } from '@/feature/toggle';
import { PageFooter, PageHeader } from '@/showcase';
import { PasswordRotationAlert } from '@/showcase/PasswordRotationAlert/PasswordRotationAlert';

import { fullPageWidthAtom } from '../atoms';

const FT_PASSWORD_EXPIRATION = featureControl.getToggle('PCP_1414__PasswordExpirationAlert');

type ComputerListPageLayoutProps = { children: React.ReactNode };
export function ComputerListPageLayout({ children }: ComputerListPageLayoutProps): React.JSX.Element {
  const { t } = useTranslation(['external', 'common']);

  return (
    <>
      <Head>
        <title>{t('common:title')}</title>
      </Head>

      <Global
        styles={css`
          body {
            background-color: ${colors.neutral[10]};
          }
        `}
      />

      <Box
        display="grid"
        gridTemplateRows="min-content 1fr min-content"
        // Minus 1px for hiding scrollbar
        minHeight="calc(100vh - 1px)"
        sx={{ footer: { display: { base: 'none', lg: 'grid' } } }}
      >
        <PageHeader />
        <Box as="main" className="main">
          {children}
        </Box>
        <ComputerListPageFooter />
        {FT_PASSWORD_EXPIRATION && <PasswordRotationAlert />}
      </Box>
    </>
  );
}

/**
 * Sticky to window left
 *
 * Reference from StickyLeftWrapper
 */
function ComputerListPageFooter(): React.JSX.Element {
  const fullPageWidth = useAtomValue(fullPageWidthAtom);

  const wrapperRef = useRef<HTMLDivElement>(null);
  const wrapperSize = useSize(wrapperRef);
  const wrapperWidth = wrapperSize ? `${wrapperSize?.width}px` : 'fit-content';

  const childrenContainerRef = useRef<HTMLDivElement>(null);
  const childrenContainerSize = useSize(childrenContainerRef);
  const childrenHeight = childrenContainerSize ? `${childrenContainerSize.height}px` : '0px';

  return (
    <Box ref={wrapperRef} position="relative" w="100%" h={childrenHeight}>
      <Box position="absolute" top="0px" left="0px" w={fullPageWidth}>
        <Box ref={childrenContainerRef} position="sticky" left="0" w={wrapperWidth}>
          <PageFooter marginTop="0px" />
        </Box>
      </Box>
    </Box>
  );
}
