import axios from 'axios';
import { z } from 'zod';

import { checkResponse } from '@/services/utils';

const Source = {
  choco: 1,
  brew: 2,
} as const;

export const SOURCE_LABEL_MAP = {
  [Source.choco]: 'Chocolatey',
  [Source.brew]: 'Homebrew',
};

/**
 * Softwares API
 * @param teamId
 * @param emmPolicyId
 * @doc https://github.com/SplashtopInc/be_kung_fu/blob/master/be_app/api/web-api/v1/emm/software_patch.md#softwares-api
 */
export const recentVersionSchema = z.object({
  software_version_id: z.union([z.string(), z.number()]),
  version: z.string(),
  desc: z.string(),
  release_date: z.string().nullable(),
});

export const softwareSchema = z.object({
  /** The ID of the software. */
  id: z.union([z.string(), z.number()]),
  /** The display name of the software. */
  name: z.string(),
  /** The source provider of the software (Chocolatey or Homebrew). */
  sp: z.union([z.literal(Source.choco), z.literal(Source.brew)]),
  /** The application name of the software, use as policy item value's `id`. */
  app: z.string(),
  /** use as policy item code. */
  policy_item_code: z.string(),
  /** Indicates if the software is enabled to be managed. */
  enabled: z.boolean(),
  /** Indicates if the software is recommended by Splashtop. If not, it's a software added by user. */
  recommended: z.boolean(),
  /** Indicates the software only support latest version, can't downgrade */
  latest_only: z.boolean(),
  icon_url: z.string(),
  descriptions: z.string(),
  recent_versions: z.array(recentVersionSchema),
});

export type Software = z.infer<typeof softwareSchema>;

const responseDataSchema = z.object({
  softwares: z.array(softwareSchema),
});
export type SoftwaresResponse = z.infer<typeof responseDataSchema>;

const getSoftwaresUrl = (teamId: number, emmPolicyId: number) =>
  `/api/web/v1/teams/${teamId}/emm_policies/${emmPolicyId}/patch_policy/softwares`;

export function getSoftwares(teamId: number, emmPolicyId: number) {
  return checkResponse(axios.get(getSoftwaresUrl(teamId, emmPolicyId)), responseDataSchema);
}

export const getSoftwaresService = {
  getUrl: getSoftwaresUrl,
  method: 'GET',
  execute: getSoftwares,
};
