import axios from 'axios';
import { z } from 'zod';

import { checkResponse } from '@/services/utils';

import { policyPlatformEnum } from '../types';
import { PolicyNode, policyNodeSchema } from './types';

/**
 * OS Patch Policy Detail API
 * @param teamId
 * @param emmPolicyId
 * @doc https://github.com/SplashtopInc/be_kung_fu/blob/master/be_app/api/web-api/v1/emm/os_patch.md#os-patch-policy-detail-api
 */

const responseDataSchema = z.object({
  category: z.literal('os'),
  platform: policyPlatformEnum, // Windows, macOS, Linux, ...etc.
  current: policyNodeSchema,
  parents: z.array(policyNodeSchema),
});
export type OSPatchPolicyDetail = z.infer<typeof responseDataSchema>;

const getOSPatchPolicyDetailUrl = (teamId: number, emmPolicyId: number) =>
  `/api/web/v1/teams/${teamId}/emm_policies/${emmPolicyId}/os_policy`;

export function getOSPatchDetail(teamId: number, emmPolicyId: number) {
  return checkResponse(axios.get(getOSPatchPolicyDetailUrl(teamId, emmPolicyId)), responseDataSchema);
}

export function getMockOSPolicyDetail() {
  const currentNode: PolicyNode = {
    emm_policy_id: 1,
    parent_id: 2,
    policy_items: [
      {
        id: 1,
        code: '02000001',
        operator: '+',
        value: true,
      },
      {
        id: 2,
        code: '02000002',
        operator: '+',
        value: 2,
      },
    ],
  };

  return checkResponse(
    new Promise((resolve) =>
      resolve({
        status: 200,
        statusText: 'OK',
        headers: {},
        config: {},
        data: {
          result: 20200,
          messages: [],
          data: {
            category: 'os',
            platform: 'Windows',
            current: currentNode,
            parents: [
              {
                emm_policy_id: 4,
                parent_id: null,
                policy_items: [
                  {
                    id: 3,
                    code: '02000001',
                    operator: '+',
                    value: true,
                  },
                  {
                    id: 4,
                    code: '02000002',
                    operator: '+',
                    value: 3,
                  },
                  {
                    id: 5,
                    code: '02000003',
                    operator: '+',
                    value: {
                      time: '45 12 * * *',
                      interval: 1800,
                    },
                  },
                  {
                    id: 6,
                    code: '02000004',
                    operator: '+',
                    value: true,
                  },
                ],
              },
              {
                emm_policy_id: 2,
                parent_id: 3,
                policy_items: [
                  {
                    id: 9,
                    code: '02000001',
                    operator: '+',
                    value: true,
                  },
                ],
              },
              {
                emm_policy_id: 3,
                parent_id: 4,
                policy_items: [
                  {
                    id: 10,
                    code: '02000001',
                    operator: '+',
                    value: true,
                  },
                  {
                    id: 11,
                    code: '02000002',
                    operator: '+',
                    value: 1,
                  },
                ],
              },
            ],
          },
        },
      }),
    ),
    responseDataSchema,
  );
}

export const getOSPatchPolicyDetailService = {
  getUrl: getOSPatchPolicyDetailUrl,
  method: 'GET',
  execute: getOSPatchDetail,
};
