const connectPermission = ['conn_perm'] as const;

const relayRecording = ['relay_recording', 'sos_relay_recording'] as const;

/**
 * ref: be-app app/models/concerns/model_configs/granular.rb CONTROLS_BY_FEATURE_CAPACITY
 */
export const granularControlKeys = [
  'attended_access',
  'file',
  'remote_print',
  'copy_paste',
  'required_2sv',
  'one_to_many',
  'command_prompt',
  'watermark',
  'remote_control',
  ...connectPermission,
  ...relayRecording,
] as const;

export const granularControlDetailSettingKeys = ['conn_perm_setting'] as const;
