import { useCallback, useEffect, useMemo } from 'react';

import { Box, Center, HStack, Stack } from '@chakra-ui/react';
import { useAtom } from 'jotai';
import cond from 'lodash/cond';
import constant from 'lodash/constant';
import groupBy from 'lodash/groupBy';
import matches from 'lodash/matches';
import stubTrue from 'lodash/stubTrue';
import { Trans, useTranslation } from 'react-i18next';
import { Virtuoso } from 'react-virtuoso';

import { POLICY_SUPPORT_ARTICLE } from '@/constants';
import {
  RippleAlertSpecificColor16,
  RippleAndroidOffline32,
  RippleAndroidOnline32,
  RippleArrowDown,
  RippleArrowRight,
  RippleCheckbox,
  RippleCount,
  RippleHighlight,
  RippleHyperLink,
  RippleIconButton,
  RippleInfo16,
  RippleListItem,
  RippleMacOffline32,
  RippleMacOnline32,
  RipplePopover,
  RipplePopoverArrow,
  RipplePopoverBody,
  RipplePopoverContent,
  RipplePopoverTrigger,
  RippleSkeleton,
  RippleTBody,
  RippleTHead,
  RippleTR,
  RippleTable,
  RippleTableGroupHead,
  RippleTooltip,
  RippleTypography,
  RippleUnorderedList,
  RippleWindowsOffline32,
  RippleWindowsOnline32,
} from '@/design';
import { featureControl } from '@/feature/toggle';
import { DEFAULT_GROUP_ID } from '@/modules/Computer';
import { GroupPolicyRelation, ServerPolicyRelation } from '@/modules/PolicyList/hooks';
import { Policy } from '@/services/teams/emm_policies';
import { getStreamerVersion } from '@/utils/computers';

import { expandedGroupsAtom } from '../atoms';
import { ServerCheckbox } from './ServerCheckbox';
import { useTableFilter } from './TableFilter';
import {
  CheckboxColumn,
  CheckboxHeader,
  IconColumn,
  IconHeader,
  NameColumn,
  NameHeader,
  PolicyColumn,
  PolicyHeader,
  VersionColumn,
  VersionHeader,
} from './columns';

type GroupTableProps = {
  keyword: string;
  isLoading: boolean;
  isAllGroupsSelected: boolean;
  currentPolicy: Policy;
  defaultPolicy: Policy;
  groupIds: Array<number>;
  groupPolicyRelation: GroupPolicyRelation;
  serverPolicyRelation: ServerPolicyRelation;
  selectedGroups: Record<number, boolean>;
  selectedServers: Record<number, boolean>;
  onSelectedGroupChange: (id: number) => void;
  onSelectedServerChange: (id: number) => void;
  onAllGroupSelect: () => void;
  isOsPatchEnabled?: boolean;
  isSoftwarePatchEnabled?: boolean;
};

export const GroupTable = ({
  keyword,
  isLoading,
  isAllGroupsSelected,
  isOsPatchEnabled,
  isSoftwarePatchEnabled,
  groupIds,
  groupPolicyRelation,
  serverPolicyRelation,
  currentPolicy,
  defaultPolicy,
  selectedGroups,
  selectedServers,
  onSelectedGroupChange,
  onSelectedServerChange,
  onAllGroupSelect,
}: GroupTableProps) => {
  const [expandedGroups, setExpandedGroups] = useAtom(expandedGroupsAtom);
  const { isFilteredByGroup, isFilteredBySelectedGroup, isFilteredBySelectedServer, isServerFilteredByPolicy, isGroupFilteredByPolicy } =
    useTableFilter();
  const { t } = useTranslation();

  const toggleGroupExpanded = useCallback(
    (groupId: number) => {
      setExpandedGroups((prev) => ({ ...prev, [groupId]: !prev[groupId] }));
    },
    [setExpandedGroups],
  );

  const serverInSameGroup = groupBy(
    Object.values(serverPolicyRelation).filter((node) => node.platform === currentPolicy.platform),
    (node) => node.groupId,
  );

  const groupedComputers = useMemo(() => {
    return (
      groupIds
        .map((id) => {
          return {
            group: groupPolicyRelation[id],
            servers: serverInSameGroup[id] ?? [],
          };
        }) // Filter by group
        .filter(({ group }) => isFilteredByGroup(group.group))
        // Filter by selected
        .map(({ group, servers }) => ({ group, servers: servers.filter((server) => isFilteredBySelectedServer(server.server)) }))
        .filter(
          ({ group, servers }) =>
            servers.length > 0 || isFilteredBySelectedGroup(group.group, group.policies[currentPolicy.platform].isFollowTeam),
        )
        // Filter by policies
        .map(({ group, servers }) => ({ group, servers: servers.filter((server) => isServerFilteredByPolicy(server.server)) }))
        .filter(({ group, servers }) => {
          return (
            servers.length > 0 ||
            isGroupFilteredByPolicy({
              groupId: group.group.id,
              platform: currentPolicy.platform,
              teamPolicyId: defaultPolicy.id,
            })
          );
        })
    );
  }, [
    currentPolicy.platform,
    defaultPolicy.id,
    groupIds,
    groupPolicyRelation,
    isFilteredByGroup,
    isFilteredBySelectedGroup,
    isFilteredBySelectedServer,
    isGroupFilteredByPolicy,
    isServerFilteredByPolicy,
    serverInSameGroup,
  ]);

  // filter by search
  const filteredBySearch = useMemo(
    () =>
      groupedComputers
        .map(({ group, servers }) => {
          const isGroupMatched =
            group.group.name.toLowerCase().includes(keyword) ||
            group.policies[currentPolicy.platform].policy.name.toLowerCase().includes(keyword);
          return {
            group,
            isGroupMatched,
            servers: servers.filter((server) => {
              if (!keyword || isGroupMatched) {
                return true;
              }
              return server.server.name.toLowerCase().includes(keyword) || server.policy.name.toLowerCase().includes(keyword);
            }),
          };
        })
        .filter((group) => {
          return group.isGroupMatched || group.servers.length > 0;
        }),
    [currentPolicy.platform, groupedComputers, keyword],
  );

  const flattenToRender = useMemo(
    () =>
      filteredBySearch.flatMap(({ group, servers }) => {
        const isSelected = selectedGroups[group.group.id];
        const isExpanded = expandedGroups[group.group.id];
        const groupPolicy = group.policies[currentPolicy.platform];
        const isGroupSelected = currentPolicy.super_root ? groupPolicy.isFollowTeam || isSelected : isSelected;
        const isAnyServerSelected = servers.some((server) => selectedServers[server.server.id]);
        const groupPolicyName = groupPolicy.policy.name;
        const isDefaultGroup = group.group.id === Number(DEFAULT_GROUP_ID);
        // Team default policy is unable unchecked
        const isDisabled = groupPolicy.policy.id === currentPolicy.id && currentPolicy.super_root;
        const serverCount = servers.length;
        return [
          {
            type: 'group' as const,
            group,
            groupPolicyName,
            serverCount,
            isSelected,
            isExpanded,
            isDisabled,
            isDefaultGroup,
            isGroupSelected,
            isAnyServerSelected,
          },
          ...(servers.length !== 0
            ? servers.map((server, index) => {
                const isAssignedTheSamePolicy = server.policy.id === currentPolicy?.id;
                const isServerSelected = selectedServers[server.server.id];
                const isInherited = isGroupSelected && !isServerSelected && (server.isFollowGroup || isAssignedTheSamePolicy);
                // If the group is selected, the row is selected if the server is not assigned to any policy
                const isRowSelected = isInherited || isServerSelected;
                // If the server is assigned to another policy, the checkbox is hidden when group is selected
                const serverPolicyName = cond([
                  [matches({ isFollowGroup: true }), constant(t('emm-policy:follow_group'))],
                  [matches({ isCustomized: true }), () => server.parentPolicy?.name],
                  [stubTrue, () => server.policy.name],
                ])(server);
                const version = getStreamerVersion(server.server.version);
                return {
                  type: 'server' as const,
                  server,
                  version,
                  serverPolicyName,
                  isExpanded,
                  isInherited,
                  isRowSelected,
                  isGroupSelected,
                  isServerSelected,
                  isAssignedTheSamePolicy,
                  isLastRow: index === servers.length - 1,
                };
              })
            : [
                {
                  type: 'no-server' as const,
                  isExpanded,
                },
              ]),
        ];
      }),
    [
      currentPolicy.id,
      currentPolicy.platform,
      currentPolicy.super_root,
      expandedGroups,
      filteredBySearch,
      selectedGroups,
      selectedServers,
      t,
    ],
  );

  useEffect(
    function initExpandGroups() {
      groupIds.length > 0 &&
        setExpandedGroups(
          groupIds.reduce(
            (acc, id) => {
              acc[id] = true;
              return acc;
            },
            {} as Record<number, boolean>,
          ),
        );
    },
    [groupIds, groupIds.length, setExpandedGroups],
  );

  const renderItemContent = useCallback(
    (index: number, item: (typeof flattenToRender)[number]) => {
      if (item.type === 'group') {
        const { group, groupPolicyName, serverCount, isExpanded, isDisabled, isDefaultGroup, isGroupSelected, isAnyServerSelected } = item;

        return (
          <RippleTBody
            key={group.group.id}
            sx={{ marginTop: index === 0 ? '0' : '12px' }}
            borderRadius={isExpanded ? '4px 4px 0 0' : '4px'}
          >
            <RippleTableGroupHead isSelected={isGroupSelected} textTransform="capitalize">
              <CheckboxColumn>
                {isDefaultGroup ? (
                  <></>
                ) : (
                  <RippleCheckbox
                    isChecked={isGroupSelected}
                    // Team default policy is unable unchecked
                    isDisabled={isDisabled}
                    isIndeterminate={!isGroupSelected && isAnyServerSelected}
                    onChange={() => {
                      onSelectedGroupChange(group.group.id);
                    }}
                  />
                )}
              </CheckboxColumn>
              <IconColumn>
                <RippleIconButton
                  aria-label="Collapse"
                  w="24px"
                  h="24px"
                  p="0"
                  icon={isExpanded ? <RippleArrowDown /> : <RippleArrowRight />}
                  onClick={() => toggleGroupExpanded(group.group.id)}
                />
              </IconColumn>
              <NameColumn>
                <HStack gap="8px">
                  <RippleTooltip label={group.group.name}>
                    <RippleTypography variant="heading07" noOfLines={1} isTruncated wordBreak="break-all" whiteSpace="break-spaces">
                      <RippleHighlight query={keyword}>{group.group.name}</RippleHighlight>
                    </RippleTypography>
                  </RippleTooltip>
                  <RippleCount variant="neutralLight" count={serverCount} />
                </HStack>
              </NameColumn>
              <PolicyColumn>
                <HStack gap="8px" alignItems="center">
                  <RippleTypography variant="body02" noOfLines={2} isTruncated wordBreak="break-all" whiteSpace="break-spaces">
                    <RippleHighlight query={keyword}>{groupPolicyName}</RippleHighlight>
                  </RippleTypography>
                  {isDefaultGroup && (
                    <RippleTooltip label={t('emm-policy:the_policy_of_default_group_cannot_be_changed')}>
                      <Center>
                        <RippleInfo16 />
                      </Center>
                    </RippleTooltip>
                  )}
                </HStack>
              </PolicyColumn>
              <VersionColumn />
            </RippleTableGroupHead>
          </RippleTBody>
        );
      }
      if (item.type === 'no-server') {
        if (!item.isExpanded) {
          return <></>;
        }
        return (
          <RippleTBody key="no-server" borderRadius="0 0 4px 4px" borderTopWidth="0">
            <RippleTR>
              <CheckboxColumn />
              <IconColumn />
              <NameColumn>
                <RippleTypography variant="body02">{t('common:no_computers')}</RippleTypography>
              </NameColumn>
              <PolicyColumn />
              <VersionColumn />
            </RippleTR>
          </RippleTBody>
        );
      }
      if (item.type === 'server') {
        const {
          server,
          version,
          serverPolicyName,
          isLastRow,
          isExpanded,
          isInherited,
          isRowSelected,
          isGroupSelected,
          isServerSelected,
          isAssignedTheSamePolicy,
        } = item;
        if (!isExpanded) {
          return <></>;
        }
        const isUnsupportToSoftwarePatch = isSoftwarePatchEnabled && !server.server.support_emm_patch_policy;
        const isUnsupportToOsPatch = isOsPatchEnabled && !server.server.support_emm_os_policy;
        return (
          <RippleTBody key={server.server.id} borderRadius={isLastRow ? '0 0 4px 4px' : '0'} borderTopWidth="0">
            <RippleTR isSelected={isRowSelected}>
              <CheckboxColumn>
                <ServerCheckbox
                  isChecked={isServerSelected}
                  isInherited={isInherited}
                  isHidden={isGroupSelected && !isAssignedTheSamePolicy}
                  onChange={() => onSelectedServerChange(server.server.id)}
                />
              </CheckboxColumn>
              <IconColumn>
                {featureControl.getToggle('PCP_1391__Policy_software_patch') && (isUnsupportToSoftwarePatch || isUnsupportToOsPatch) && (
                  <RipplePopover trigger="hover">
                    <RipplePopoverTrigger>
                      <Center>
                        <RippleAlertSpecificColor16 />
                      </Center>
                    </RipplePopoverTrigger>
                    <RipplePopoverContent>
                      <RipplePopoverArrow />
                      <RipplePopoverBody>
                        <Stack gap="4px">
                          <RippleTypography variant="body02" color="neutralDark">
                            {t('emm-policy:software_patch_not_supported')}
                          </RippleTypography>
                          <RippleUnorderedList pl="8px">
                            {isUnsupportToSoftwarePatch && (
                              <RippleListItem>
                                <RippleTypography variant="body02" color="neutralDark">
                                  {t('emm-policy:software_patch')}
                                </RippleTypography>
                              </RippleListItem>
                            )}
                            {featureControl.getToggle('PCP_1682__Policy_os_patch') && isUnsupportToOsPatch && (
                              <RippleListItem>
                                <RippleTypography variant="body02" color="neutralDark">
                                  {t('emm-policy:os_patch')}
                                </RippleTypography>
                              </RippleListItem>
                            )}
                          </RippleUnorderedList>
                          <RippleTypography variant="body02">
                            <Trans
                              i18nKey="emm-policy:learn_more_about_support_matrix"
                              components={{
                                Link: <RippleHyperLink variant="hyperlink02" target="_blank" href={POLICY_SUPPORT_ARTICLE} />,
                              }}
                            />
                          </RippleTypography>
                        </Stack>
                      </RipplePopoverBody>
                    </RipplePopoverContent>
                  </RipplePopover>
                )}
              </IconColumn>
              <NameColumn gap="16px">
                {server.platform === 'Windows' && (server.server.online_status ? <RippleWindowsOnline32 /> : <RippleWindowsOffline32 />)}
                {server.platform === 'macOS' && (server.server.online_status ? <RippleMacOnline32 /> : <RippleMacOffline32 />)}
                {featureControl.getToggle('PCP_2647__Policy_support_android_platform') &&
                  server.platform === 'Android' &&
                  (server.server.online_status ? <RippleAndroidOnline32 /> : <RippleAndroidOffline32 />)}
                <RippleTypography variant="body02" noOfLines={2} isTruncated wordBreak="break-all" whiteSpace="break-spaces">
                  <RippleHighlight query={keyword}>{server.server.name}</RippleHighlight>
                </RippleTypography>
              </NameColumn>
              <PolicyColumn>
                <HStack gap="8px">
                  <RippleTypography variant="body02" noOfLines={2} isTruncated wordBreak="break-all" whiteSpace="break-spaces">
                    <RippleHighlight query={keyword}>{serverPolicyName ?? ''}</RippleHighlight>
                  </RippleTypography>
                  {server.isCustomized && (
                    // TODO: Change it to new RippleChip component when new component ready
                    <Box p="2px 8px" border="1px solid" borderColor="blue.60" borderRadius="16px" bg="blue.10" flexShrink="0">
                      <RippleTypography variant="body03">{t('emm-policy:overridden')}</RippleTypography>
                    </Box>
                  )}
                </HStack>
              </PolicyColumn>
              <VersionColumn>
                <RippleTypography variant="body02">{version}</RippleTypography>
              </VersionColumn>
            </RippleTR>
          </RippleTBody>
        );
      }
      return <></>;
    },
    [isOsPatchEnabled, isSoftwarePatchEnabled, keyword, onSelectedGroupChange, onSelectedServerChange, t, toggleGroupExpanded],
  );

  return (
    <RippleTable colorScheme="white">
      <RippleTHead>
        <RippleTR w="100%">
          <CheckboxHeader>
            <RippleCheckbox isChecked={isAllGroupsSelected} onChange={onAllGroupSelect} />
          </CheckboxHeader>
          <IconHeader />
          <NameHeader>{t('common:name')}</NameHeader>
          <PolicyHeader>{t('emm-policy:endpoint_policy')}</PolicyHeader>
          <VersionHeader>
            <HStack gap="4px" alignItems="center">
              <RippleTypography variant="heading09">{t('emm-policy:streamer_version')}</RippleTypography>
              <RipplePopover placement="bottom">
                <RipplePopoverTrigger>
                  <Center cursor="pointer">
                    <RippleInfo16 />
                  </Center>
                </RipplePopoverTrigger>
                <RipplePopoverContent>
                  <RipplePopoverArrow />
                  <RipplePopoverBody>
                    <RippleTypography variant="body02" color="black">
                      <Trans
                        i18nKey="emm-policy:only_streamer_versions_or_later_are_listed_here_learn_more_about_feature_support_matrix"
                        values={{
                          version: '3.7.0.0',
                        }}
                        components={{
                          Link: <RippleHyperLink variant="hyperlink02" target="_blank" href={POLICY_SUPPORT_ARTICLE} />,
                        }}
                      />
                    </RippleTypography>
                  </RipplePopoverBody>
                </RipplePopoverContent>
              </RipplePopover>
            </HStack>
          </VersionHeader>
        </RippleTR>
      </RippleTHead>
      {isLoading ? (
        <RippleTBody>
          <RippleTableGroupHead textTransform="capitalize">
            <CheckboxColumn>
              <RippleCheckbox isChecked={false} />
            </CheckboxColumn>
            <IconColumn>
              <RippleIconButton aria-label="Collapse" w="24px" h="24px" p="0" icon={<RippleArrowDown />} />
            </IconColumn>
            <NameColumn>
              <RippleSkeleton w="160px" />
            </NameColumn>
            <PolicyColumn>
              <RippleSkeleton w="160px" />
            </PolicyColumn>
            <VersionColumn>
              <RippleSkeleton w="160px" />
            </VersionColumn>
          </RippleTableGroupHead>
          <RippleTR>
            <CheckboxColumn>
              <ServerCheckbox onChange={() => {}} />
            </CheckboxColumn>
            <IconColumn />
            <NameColumn>
              <RippleSkeleton w="200px" />
            </NameColumn>
            <PolicyColumn>
              <RippleSkeleton w="200px" />
            </PolicyColumn>
            <VersionColumn>
              <RippleSkeleton w="160px" />
            </VersionColumn>
          </RippleTR>
        </RippleTBody>
      ) : (
        <Virtuoso style={{ height: '520px', width: '100%' }} data={flattenToRender} itemContent={renderItemContent} />
      )}
    </RippleTable>
  );
};
