import { Dictionary } from 'lodash';
import { z } from 'zod';

import { softwareSchema } from '@/services/teams/emm_policies/patch_policy/getSoftwares';

import { createPolicyItemSchema } from '../types';
import {
  notifyBeforeRebootSecondMap,
  patchPolicyItemConfig,
  rebootIntervalSecondMap,
  sourceProviderMap,
  updateAfterReleaseDayMap,
} from './constants';

const enablePatchPolicySchema = createPolicyItemSchema(patchPolicyItemConfig.enable_policy.code, z.boolean());

export const serviceProviderSourceSchema = z.object({
  alias: z.string().nullable().optional(),
  order: z.number(),
  password: z.string().nullable().optional(),
  proxy: z
    .object({
      auto: z.boolean(),
      password: z.string().nullable().optional(),
      port: z.number().nullable().optional(),
      url: z.string().nullable().optional(),
      user: z.string().nullable().optional(),
    })
    .nullable(),
  source_url: z.string().nullable(),
  tap: z.string().nullable().optional(),
  user: z.string().nullable().optional(),
});
type ServiceProviderSource = z.infer<typeof serviceProviderSourceSchema>;

export const serviceProviderSchema = createPolicyItemSchema(
  patchPolicyItemConfig.service_provider.code,
  z.object({
    source: z.array(serviceProviderSourceSchema),
    sp: z.union([z.literal(sourceProviderMap.Windows), z.literal(sourceProviderMap.macOS)]),
  }),
);

const scanScheduleSchema = createPolicyItemSchema(
  patchPolicyItemConfig.scan_schedule.code,
  z.object({ interval: z.number().max(9999999).min(0), time: z.string() }),
);

const updateScheduleSchema = createPolicyItemSchema(
  patchPolicyItemConfig.update_schedule.code,
  z.object({ interval: z.number().max(9999999).min(0), time: z.string() }),
);

const makeUpScanSchema = createPolicyItemSchema(patchPolicyItemConfig.make_up_scan.code, z.boolean());

const makeUpUpdateSchema = createPolicyItemSchema(patchPolicyItemConfig.make_up_update.code, z.boolean());

const onboardingScanUpdateSchema = createPolicyItemSchema(patchPolicyItemConfig.onboarding_scan_update.code, z.boolean());

const updateAfterReleaseDaySchema = z.union([
  z.literal(updateAfterReleaseDayMap.none),
  z.literal(updateAfterReleaseDayMap.sevenDay),
  z.literal(updateAfterReleaseDayMap.fourteenDay),
  z.literal(updateAfterReleaseDayMap.thirtyDay),
]);
export type UpdateAfterReleaseDay = z.infer<typeof updateAfterReleaseDaySchema>;

const updateAfterReleaseDayListSchema = z.array(updateAfterReleaseDaySchema);
type UpdateAfterReleaseListDay = z.infer<typeof updateAfterReleaseDayListSchema>;

const updateAfterReleaseSchema = createPolicyItemSchema(patchPolicyItemConfig.update_after_release.code, updateAfterReleaseDaySchema);
export type UpdateAfterRelease = z.infer<typeof updateAfterReleaseSchema>;

const rebootIntervalMinuteSchema = z.union([
  z.literal(rebootIntervalSecondMap.none),
  z.literal(rebootIntervalSecondMap.fifteenMinutes),
  z.literal(rebootIntervalSecondMap.thirtyMinutes),
  z.literal(rebootIntervalSecondMap.sixtyMinutes),
  z.literal(rebootIntervalSecondMap.ninetyMinutes),
  z.literal(rebootIntervalSecondMap.oneHundredTwentyMinutes),
]);
export type RebootIntervalMinute = z.infer<typeof rebootIntervalMinuteSchema>;

const rebootIntervalMinuteListSchema = z.array(rebootIntervalMinuteSchema);
export type RebootIntervalMinuteList = z.infer<typeof rebootIntervalMinuteListSchema>;

const notifyBeforeRebootMinuteSchema = z.union([
  z.literal(notifyBeforeRebootSecondMap.none),
  z.literal(notifyBeforeRebootSecondMap.twoMinutes),
  z.literal(notifyBeforeRebootSecondMap.threeMinutes),
  z.literal(notifyBeforeRebootSecondMap.fourMinutes),
  z.literal(notifyBeforeRebootSecondMap.fiveMinutes),
]);
export type NotifyBeforeRebootMinute = z.infer<typeof notifyBeforeRebootMinuteSchema>;

const notifyBeforeRebootMinuteListSchema = z.array(notifyBeforeRebootMinuteSchema);
export type NotifyBeforeRebootMinuteList = z.infer<typeof notifyBeforeRebootMinuteListSchema>;

const rebootIntervalSchema = createPolicyItemSchema(
  patchPolicyItemConfig.reboot_interval.code,
  z.object({
    interval: rebootIntervalMinuteSchema,
    login_give_up: z.boolean(),
    notify_before_reboot: notifyBeforeRebootMinuteSchema,
    reboot: z.boolean(),
  }),
);
export type RebootInterval = z.infer<typeof rebootIntervalSchema>;

export const patchPolicySoftwareItemSchema = z.object({
  id: z.number().optional(),
  code: z.string().regex(/^030[1-2](?!0000)[0-9]{4}$/),
  operator: z.union([z.literal('+'), z.literal('-')]),
  value: z
    .object({
      sp: softwareSchema.shape.sp,
      id: softwareSchema.shape.app,
      auto_approval: z.boolean(),
      required: z.boolean(),
      approved_version: z.string().nullable(),
    })
    .nullable(),
});
export type PatchPolicySoftwareItem = z.infer<typeof patchPolicySoftwareItemSchema>;

// For policy item with fixed code
export const policyFixedCodeItemSchema = z.discriminatedUnion('code', [
  enablePatchPolicySchema,
  serviceProviderSchema,
  scanScheduleSchema,
  updateScheduleSchema,
  makeUpScanSchema,
  makeUpUpdateSchema,
  onboardingScanUpdateSchema,
  updateAfterReleaseSchema,
  rebootIntervalSchema,
]);
export type PatchPolicyFixedCodeItem = z.infer<typeof policyFixedCodeItemSchema>;
export type PolicyItem = PatchPolicyFixedCodeItem | PatchPolicySoftwareItem;
export type PolicyItemWithoutId = Omit<PolicyItem, 'id'>;

export const policyNodeIdsSchema = z.object({
  emm_policy_id: z.number(),
  parent_id: z.union([z.number(), z.null()]),
});

const policyItems = z.array(
  z.union([
    enablePatchPolicySchema,
    serviceProviderSchema,
    scanScheduleSchema,
    updateScheduleSchema,
    makeUpScanSchema,
    makeUpUpdateSchema,
    onboardingScanUpdateSchema,
    updateAfterReleaseSchema,
    rebootIntervalSchema,
    patchPolicySoftwareItemSchema,
  ]),
);
export type PolicyItems = z.infer<typeof policyItems>;

export const policyNodeSchema = policyNodeIdsSchema.merge(
  z.object({
    policy_items: policyItems,
  }),
);
export type PolicyNode = z.infer<typeof policyNodeSchema>;

export const patchPolicyFixedCodeMapSchema = z.object({
  [enablePatchPolicySchema.shape.code.value]: enablePatchPolicySchema,
  [serviceProviderSchema.shape.code.value]: serviceProviderSchema,
  [scanScheduleSchema.shape.code.value]: scanScheduleSchema,
  [updateScheduleSchema.shape.code.value]: updateScheduleSchema,
  [makeUpScanSchema.shape.code.value]: makeUpScanSchema,
  [makeUpUpdateSchema.shape.code.value]: makeUpUpdateSchema,
  [onboardingScanUpdateSchema.shape.code.value]: onboardingScanUpdateSchema,
  [updateAfterReleaseSchema.shape.code.value]: updateAfterReleaseSchema,
  [rebootIntervalSchema.shape.code.value]: rebootIntervalSchema,
});
export type PolicyFixedCodeMapItem = z.infer<typeof patchPolicyFixedCodeMapSchema>;

// @doc `null` represents current no rule for this policy item
export type PolicyCodeRuleMapItem = {
  [patchPolicyItemConfig.enable_policy.code]: null;
  [patchPolicyItemConfig.service_provider.code]: Array<ServiceProviderSource>;
  [patchPolicyItemConfig.scan_schedule.code]: {
    interval: 1800; // Represents max value for endpoint
  };
  [patchPolicyItemConfig.update_schedule.code]: {
    interval: 1800; // Represents max value for endpoint
  };
  [patchPolicyItemConfig.make_up_scan.code]: null;
  [patchPolicyItemConfig.make_up_update.code]: null;
  [patchPolicyItemConfig.onboarding_scan_update.code]: null;
  [patchPolicyItemConfig.update_after_release.code]: {
    days: UpdateAfterReleaseListDay;
  };
  [patchPolicyItemConfig.reboot_interval.code]: {
    interval: {
      minute: RebootIntervalMinuteList;
    };
    notify_before_reboot: {
      minute: NotifyBeforeRebootMinuteList;
    };
  };
};

export type PatchPolicyUnit = {
  setting: Partial<PolicyFixedCodeMapItem>;
  software: Dictionary<PatchPolicySoftwareItem>;
};

export type PatchPolicyCompare = {
  current: PatchPolicyUnit;
  parent: PatchPolicyUnit;
};

export type PolicyItemDefaultValueMap = {
  [K in keyof PolicyFixedCodeMapItem]: PolicyItemInheritedStatus<K>['defaultInfo'];
};

export type PolicyItemInheritedStatus<K extends keyof PolicyFixedCodeMapItem> = {
  isCurrentAdded: boolean;
  defaultInfo: { isRequired: boolean; default: PolicyFixedCodeMapItem[K]['value']; rule: PolicyCodeRuleMapItem[K] };
  displayValue: PolicyFixedCodeMapItem[K]['value'];
  savedValue: PolicyFixedCodeMapItem[K]['value'];
};
