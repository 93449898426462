import { AutoUpdatePolicy } from '@/services/teams/emm_policies/os_patch/types';

/**
 * @description policy item config for os patch
 * @doc "0201[0-9]{3}[1-9]{1}"
 */
export const osPatchPolicyItemConfig = {
  enable_policy: {
    code: '02000001',
  },
  auto_update_policy: {
    code: '02000002',
  },
  scan_schedule: {
    code: '02000003',
  },
  make_up_scan: {
    code: '02000004',
  },
} as const;

export const autoUpdatePolicyOptionMap = {
  auto: 1,
  download_updates: 2,
  check_updates: 3,
} as const;

export const osPatchDefaultValues = {
  [osPatchPolicyItemConfig.enable_policy.code]: {
    isRequired: false,
    default: false,
    rule: null,
  },
  [osPatchPolicyItemConfig.auto_update_policy.code]: {
    isRequired: true,
    default: autoUpdatePolicyOptionMap.download_updates,
    rule: null,
  },
  [osPatchPolicyItemConfig.scan_schedule.code]: {
    isRequired: true,
    default: {
      interval: 1800,
      time: '0 2 * * * *',
    },
    rule: {
      interval: 1800,
    },
  },
  [osPatchPolicyItemConfig.make_up_scan.code]: {
    isRequired: false,
    default: false,
    rule: null,
  },
} as const;

export const autoUpdatePolicyStringToValueMap: Record<string, AutoUpdatePolicy['value']> = {
  '1': autoUpdatePolicyOptionMap.auto,
  '2': autoUpdatePolicyOptionMap.download_updates,
  '3': autoUpdatePolicyOptionMap.check_updates,
} as const;
